import  React, { useState } from "react";
import {  Button,Modal,Form } from "react-bootstrap";
export default function RoleFormDialogue(props) {
    const { show, handleClose } = props; 
    return (
        <>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Role</Modal.Title>
        </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formAmount">
                  <Form.Label>Module Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Module Name" />
              </Form.Group>
            </Form>
          </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    )
}