import  React, { useState } from "react";
import { Card, CardBody, Col, Nav, CardHeader, Button  } from "react-bootstrap";
import DataTableComponent from "../../../../partials/DataTableComponent";

export default function UnverifiedUsersList(props) {
    const { data,columns,  handleShow } = props;
    return (
        <>
        <Col xs={12}>
            <Card>
                <CardHeader className="d-flex">
                    <h5>Email Unverified Users</h5>
                </CardHeader>
                <CardBody>
                <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                </CardBody>
            </Card>
        </Col>
        </>
    )
}