import  React,{useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import AuthUserFormDialogue from "./components/Form";
import AuthUserListPage from "./components/List.component";

export default function AuthUserComponentPage() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => {
      setShow(true)
    }
    const handleEdit = (row) => {
      setShow(true)
    }
    const handleDelete = (id) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
        if (confirmDelete) {
            console.log(`Deleted ID: ${id}`);
        }
    }
    const columns = [
      {
        name: 'No',
        selector: row => row.id,
        sortable: true,
      },
      {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
      },
      {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
      },
      {
        name: 'Roles',
        sortable: true,
        cell: row => (
          <div>
            <span style={{ fontSize: '14px' }} className='badge text-white text-bg-success'>
              {row.role}
            </span>
          </div>
        ),
      },
      {
          name: 'Action',
          cell: row => (
            <div>
              <button 
                onClick={() => handleEdit(row)} 
                className="btn btn-secondary me-2"
                title="Edit"
              >
                <FontAwesomeIcon icon={faPencilAlt } />
              </button>
              <button 
                onClick={() => handleDelete(row.id)} 
                className="btn btn-danger"
                title="Delete"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          ),
        },
    ];
    const data = [
      { id: 1, name: 'Support', email: 'support@gmail.com', role: 'Support' },
      { id: 2, name: 'Admin', email: 'admin@gmail.com', role: 'Admin' }
  ];
  
    return(
        <>
        <AuthUserListPage data={data} columns={columns}  handleShow={handleShow}/>
        <AuthUserFormDialogue
            show={show}
            handleClose={handleClose}
          />
        </>
    )
}