import  React, { useState } from "react";
import {  Button,Modal,Form } from "react-bootstrap";
export default function StoreDataFormDialogue(props) {
    const { show, handleClose } = props; 
    return (
        <>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Stored Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Form.Group className="mb-3" controlId="formPaymentMethod">
                <Form.Label>Payment Method</Form.Label>
                <Form.Select aria-label="Default select example">
                    <option>Payment Method</option>
                    <option value="1">Wallet</option>
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="text" placeholder="Amount" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formSendorNumber">
                <Form.Label>Sender Number</Form.Label>
                <Form.Control type="text" placeholder="Sender Number" />
            </Form.Group>
        </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    )
}