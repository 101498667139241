import React, { useState } from "react";
import { Button, Modal, Form, FormControl, Row, Col } from "react-bootstrap";

export default function LeagueFormDialogue(props) {
  const { show, handleClose, handleImageChange, imagePreview, row, setRow, validate,users } = props;

  const [fields, setFields] = useState([""]);
  const [participatefields, setParticipateFields] = useState([""]);
 

  const handleAddField = () => {
    setFields([...fields, ""]);
  };

  const handleAddParticipateField = () => {
    setParticipateFields([...participatefields, ""]);
  };

  

  const handleParticipateFieldChange = (index, value) => {
    const newParticipateFields = [...participatefields];
    newParticipateFields[index] = value;
    setParticipateFields(newParticipateFields);
    setRow({
      ...row,
      levelparticipants : newParticipateFields
    })
  };

  const handleFieldChange = (index, value) => {
    const newFields = [...fields];
    newFields[index] = value;
    setFields(newFields);
    setRow({
      ...row,
      levelPercentages : newFields
    })
  };

  const handleDeleteField = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setRow({
      ...row,
      levelPercentages : newFields
    })
    setFields(newFields);
  };

  const handleDeleteParticipateField = (index) => {
    const newFields = participatefields.filter((_, i) => i !== index);
    setRow({
      ...row,
      levelparticipants : newFields
    })
    setParticipateFields(newFields);
  };

  

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add League</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => {
          e.preventDefault();
            validate(row);
          }}>
        <Modal.Body>
       
            <Row>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Total Participants</b>
                  </Form.Label>
                  <Form.Control 
                  type="number" 
                  placeholder="Total Participants"
                  name="totalParticipants"
                  required
                  onChange={(e) =>
                    setRow({
                      ...row,
                      totalParticipants: e.target.value
                    })
                  }
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Top Level Participants</b>
                  </Form.Label>
                  <Form.Control 
                    type="number"
                    required
                    placeholder="Top Level Participants" 
                    name="topLevelParticipants"
                    onChange={(e) =>
                      setRow({
                        ...row,
                        topLevelParticipants: e.target.value
                      })
                    }
                  />
                </Form.Group>
              </Col>
              {/* <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Top Prize Pool</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    required
                    placeholder="Enter Total Prize Pool"
                    name="totalPrizePool"
                    onChange={(e) =>
                      setRow({
                        ...row,
                        totalPrizePool: e.target.value
                      })
                    }
                  />
                </Form.Group>
              </Col> */}
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Status</b>
                  </Form.Label>
                  <Form.Select
                    name="status"
                    style={{ padding: ".7rem 1rem" }}
                    aria-label="Default select example"
                    onChange={(e) =>
                      setRow({
                        ...row,
                        status: e.target.value
                      })
                    }
                  >
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              {/* <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Allow Auto</b>
                  </Form.Label>
                  <Form.Select
                    name="allowauto"
                    style={{ padding: ".7rem 1rem" }}
                    aria-label="Default select example"
                    onChange={(e) =>
                      setRow({
                        ...row,
                        allowauto: e.target.value
                      })
                    }
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Col> */}
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Margin Percentage</b>
                  </Form.Label>
                  <Form.Control 
                    type="number"
                    required
                    placeholder="Enter Margin Percentage"
                    name="marginPercentage"
                    onChange={(e) =>
                      setRow({
                        ...row,
                        marginPercentage: e.target.value
                      })
                    }
                  />
                </Form.Group>
              </Col>
              {/* <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> User</b>
                  </Form.Label>
                  <Form.Select
                    name="user_id"
                    required
                    style={{ padding: ".7rem 1rem" }}
                    aria-label="Default select example"
                    onChange={(e) =>
                      setRow({
                        ...row,
                        user_id: e.target.value
                      })
                    }
                  >
                    <option value="">Select User</option>
                    {users && users.map((value, index) => {
                      return <option key={index} value={value.name}>{value.name}</option>;
                    })}
                    
                  </Form.Select>
                </Form.Group>
              </Col> */}
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Entry Fees</b>
                  </Form.Label>
                  <Form.Control 
                    type="number"
                    required
                    placeholder="Enter Fees"
                    name="entryfees"
                    onChange={(e) =>
                      setRow({
                        ...row,
                        entryfees: e.target.value
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                <Form.Label>
                  <b>Level Participants</b>
                </Form.Label>
                <Button 
                  style={{ float: "right" }} 
                  variant="secondary" 
                  onClick={handleAddParticipateField} 
                  size="sm" 
                >
                  Add More
                </Button>


                  {participatefields.map((field, index) => (
                    <div key={index} className="d-flex align-items-center mb-2 mt-3">
                      <Form.Control
                        type="text"
                        placeholder={`Field ${index + 1}`}
                        onChange={(e) =>
                          handleParticipateFieldChange(index, e.target.value)
                        }
                        className="me-2" 
                      />
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteParticipateField(index)}
                      >
                        Delete
                      </Button>
                    </div>
                  ))}
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Level Percentages</b>
                  </Form.Label>
                  <Button 
                    style={{ float: "right" }} 
                    variant="secondary" 
                    onClick={handleAddField} 
                    size="sm" 
                  >
                    Add More
                  </Button>
                  {fields.map((field, index) => (
                    <div key={index} className="d-flex align-items-center mb-2 mt-3">
                      <Form.Control
                        type="text"
                        placeholder={`Field ${index + 1}`}
                        value={field}
                        onChange={(e) =>
                          handleFieldChange(index, e.target.value)
                        }
                        className="me-2" 
                      />
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteField(index)}
                      >
                        Delete
                      </Button>
                    </div>
                  ))}
                  
                </Form.Group>
              </Col>
            </Row>
          
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary">Submit</Button>
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
