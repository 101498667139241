import * as actionTypes from "./appInfoActionType";
import axios from "axios";
const { REACT_APP_CRICKET_URL } = process.env;
 
export const getAllAppInfo = () => {
  const headers = {};
    return (dispatch) => {
        dispatch(getAllAppInfoInit());
        axios.get(`${REACT_APP_CRICKET_URL}/appinfo/getappInfo`, {})
          .then((response) => {
            dispatch(getAllAppInfoSuccess(response.data));
          })
          .catch(function (error) {
            dispatch(getAllAppInfoFailure());
          });
      };
}
export const getAllAppInfoInit = () => ({
    type: actionTypes.GET_ALL_APP_INFO_INIT,
});
export const getAllAppInfoFailure = () => ({
    type: actionTypes.GET_ALL_APP_INFO_FAILURE,
});
export const getAllAppInfoSuccess = (data) => ({
    type: actionTypes.GET_ALL_APP_INFO_SUCCESS,
    payload: { data },
});

export const createAppInfo = (data) => {
  data.versionios = data.versionIOS ? data.versionIOS : '';
  data.versionandroid = data.versionAndriod ? data.versionAndriod : ''; 
  data.builddate = data.buildDate ? data.buildDate : '';
  return async (dispatch) => {
      dispatch(CreateAppInfoInit());
      await axios.post(`${REACT_APP_CRICKET_URL}/appinfo/appInfo`, data, {} )
        .then((response) => {
          dispatch(CreateAppInfoSuccess(response.data));
        })
        .catch(function (error) {
          dispatch(CreateAppInfoFailure());
        });
    };
}

export const CreateAppInfoInit = () => ({
  type: actionTypes.CREATE_APP_INFO_INIT,
});
export const CreateAppInfoFailure = () => ({
  type: actionTypes.CREATE_APP_INFO_FAILURE,
});
export const CreateAppInfoSuccess = (data) => ({
  type: actionTypes.CREATE_APP_INFO_SUCCESS,
  payload: { data },
});

