import * as actionType from "./bannerActionType";

const initialState = {
    isLoading: false,
    isSaved: false,
    result: [],
    metadata : [],
    count:0
};
export const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_ALL_BANNER_INIT:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.GET_ALL_BANNER_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.GET_ALL_BANNER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                result: action?.payload?.data || [],
                metadata: action?.payload?.metadata || [],
            };
        default: 
            return state;
    }
}