import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import RunningEventFormDialogue from "./components/Form";
import ResultsList from "./components/List";

export default function WinPage() {
  const [show, setShow] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setImagePreview(null);
    setShow(true);
  };
  const handleEdit = (row) => {
    console.log(`Edit clicked for ID: ${row.id}`);
    setShow(true);
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ID: ${id}?`
    );
    if (confirmDelete) {
      // Proceed with the delete action
      console.log(`Deleted ID: ${id}`);
    }
  };
  
  const data = [
    {
      id: 1,
      name: '',
      thread: "",
      ratio: "",
      predictAmt: "",
      returnAmt: "",
      status: "",
    },

  ];

  const columns = [
    {
      name: "SN",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "THREAD",
      selector: (row) => row.thread,
    },
    {
      name: "RATIO",
      selector: (row) => row.ratio,
    },
    {
      name: "TOTAL PREDICT AMOUNT",
      selector: (row) => row.predictAmt,
    },
    {
      name: "TOTAL RETURN AMOUNT",
      selector: (row) => row.returnAmt,
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
    },
    {
      name: 'Action',
      cell: row => (
        <div>
          <button 
            onClick={() => handleEdit(row)} 
            className="btn btn-link"
            title="Edit"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
          <button 
            onClick={() => handleDelete(row.id)} 
            className="btn btn-link text-danger"
            title="Delete"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <ResultsList
        data={data}
        columns={columns}
        handleShow={handleShow}
      />
      <RunningEventFormDialogue
        show={show}
        handleClose={handleClose}
        handleImageChange={handleImageChange}
        imagePreview={imagePreview}
      />
    </>
  );
}
