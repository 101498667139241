import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoginPageContainer } from './views/login/Login.container';
import { DashboardPageContainer } from './views/dashboard/Dashboard.container';
import { PaymentPageContainer } from './views/payment/Payment.container';
import { StoreDataPageContainer } from './views/storeData/StoreData.container';
import { ManageInvoiceListContainer } from './views/manageInvoice/ManageInvoice.container';
import { ManageInvoiceAddorEditPageContainer } from './views/manageInvoice/components/Form.container';
import { UserPageContainer } from './views/user/User.container';
import { ChangePasswordPageContainer } from './views/changePassword/ChangePassword.container';
import { RolePageContainer } from './views/role/Role.container';
import { BannerPageContainer } from './views/banner/Banner.container';
import { MobailBankPageContainer } from './views/Payment-Setting/mobailBank/MobailBank.container';
import { MobailBankFormPageContainer } from './views/Payment-Setting/mobailBank/component/Form.container';
import { BankTransferListContainer } from './views/Payment-Setting/bankTransfer/BankTransfer.container';
import { BankTransferPageContainer } from './views/Payment-Setting/bankTransfer/components/Form.container';
import { RoleAccessPageContainer } from './views/role/RoleAccess.container';
import { LeagueComponentPageContainer } from './views/Game/League/League.container';
import { LeagueFormPageContainer } from './views/Game/League/components/Form.container';
import { TournamentComponentListPageContainer } from './views/Game/Tournament/Tournament.container';
import { TournamentComponentFormPageContainer } from './views/Game/Tournament/components/Form.container';
import { PlayerListPageContainer } from './views/Game/Player/Player.container';
import { PlayerFormPageContainer } from './views/Game/Player/components/Form.container';
import { RewardPageContainer } from './views/promotion/Promotion.container';


import { SystemTemplateFormPageContainer } from './views/System-Setting/systemTemplate/SystemTemplate.container';
import { CustomCssFormPageContainer } from './views/System-Setting/customCss/CustomCss.container';
import { MailPageContainer } from './views/System-Setting/mail/Mail.container';
import { ActiveLiscencePageContainer } from './views/System-Setting/activeLiscence/Mail.container';
import { OneSignalPageContainer } from './views/System-Setting/Notifications/oneSignal/OneSignal.container';
import { EmailPageContainer } from './views/System-Setting/Notifications/email/Email.container';
import { InternationalPageContainer } from './views/Payment-Setting/international/International.container';
import { AllEventMatchPageContainer } from './views/Match/allEventMatch/AllEventMatch.container';
import { RunningEventMatchPageContainer } from './views/Match/runningEventMatch/RunningEventMatch.container';
import { UpcomingEventMatchPageContainer } from './views/Match/upcomingEventMatch/UpcomingEventMatch.container';
import { AllBetsLogPageContainer } from './views/Bets/all-bets-log/AllBetsLog.container';
import { PendingBetsLogPageContainer } from './views/Bets/pending-bets-log/PendingBetsLog.container';
import { WonBetsLogPageContainer } from './views/Bets/won-bets-log/WonBetsLog.container';
import { LostBetsLogPageContainer } from './views/Bets/lost-bets-log/LostBetsLog.container';
import { RefundedBetsLogPageContainer } from './views/Bets/refunded-bets-log/RefundedBetsLog.container';
import { ResultsPageContainer } from './views/results/Results.container';
import { WinPageContainer } from './views/who-will-win/Win.container';
import { ManageUsersPageContainer } from './views/Manage-Users/manageUsers/ManageUsers.container';
import { UserDetailPageContainer } from './views/Manage-Users/userDetail/UserDetail.container';
import { ManageActiveUsersPageContainer } from './views/Manage-Users/manageActiveUsers/ManageActiveUsers.container';
import { ManageBannedUsersPageContainer } from './views/Manage-Users/manageBannedUsers/ManageBannedUsers.container';
import { ManageUnverifiedUsersPageContainer } from './views/Manage-Users/manageUnverifiedUsers/ManageUnverifiedUsers.container';
import { SettingsPageContainer } from './views/settings/Settings.container';
import { TicketPageContainer } from './views/ticket/Ticket.container';
import { PermissionComponentPageContainer } from './views/Acl-Manage/Permissions/Permission.container';
import { AuthUserComponentPageContainer } from './views/Acl-Manage/AuthUser/Authuser.container';
import { RolesComponentPageContainer } from './views/Acl-Manage/Roles/Roles.container';
import { DepositLogComponentPageContainer } from './views/Deposits/DepositLog/DepositLog.container';
import { PendingDepositLogComponentPageContainer } from './views/Deposits/PendingDepositLog/PendingDepositLog.container';
import { RejectedDepositLogComponentPageContainer } from './views/Deposits/RejectedDepositLog/RejectedDepositLog.container';
import { AcceptedDepositLogComponentPageContainer } from './views/Deposits/AcceptedDepositLog/AcceptedDepositLog.container';
import { MatchQuestionsPageContainer } from './views/Match/matchQuestions/MatchQuestions.container';

import { AppInfoPageContainer } from './views/appInfo/AppInfo.container';
import { LocationPageContainer } from './views/state/Location.container';
import { NotificationPageContainer } from './views/Manage-Users/notification/Notification.container';
import { SingleEventMatchPageContainer } from './views/Match/allEventMatch/components/SingleEvent.container';
import ProtectedRoute from './components/ProtectedRoute';

export const RoutePath = () => (
  <Routes>
    <Route exact path="/"  element={<LoginPageContainer/>} />
    

    <Route exact path="/admin/dashboard" element={ <ProtectedRoute isAuthenticated={true} element={DashboardPageContainer} />}/>
    <Route exact path="/admin/payment" element={ <ProtectedRoute isAuthenticated={true} element={PaymentPageContainer} />}/>
    <Route exact path="/admin/stored-data" element={ <ProtectedRoute isAuthenticated={true} element={StoreDataPageContainer} />}/>
    <Route exact path="/admin/manage-invoice" element={ <ProtectedRoute isAuthenticated={true} element={ManageInvoiceListContainer} />}/>
    <Route exact path="/admin/add-invoice" element={ <ProtectedRoute isAuthenticated={true} element={ManageInvoiceAddorEditPageContainer} />}/>
    <Route exact path="/admin/users" element={ <ProtectedRoute isAuthenticated={true} element={UserPageContainer} />}/>
    <Route exact path="/admin/change-password" element={ <ProtectedRoute isAuthenticated={true} element={ChangePasswordPageContainer} />}/>
    <Route exact path="/admin/role" element={ <ProtectedRoute isAuthenticated={true} element={RolePageContainer} />}/>
    <Route exact path="/admin/banner" element={ <ProtectedRoute isAuthenticated={true} element={BannerPageContainer} />}/>
    <Route exact path="/admin/mobail-bank" element={ <ProtectedRoute isAuthenticated={true} element={MobailBankPageContainer} />}/>
    <Route exact path="/admin/add-mobail-bank" element={ <ProtectedRoute isAuthenticated={true} element={MobailBankFormPageContainer} />}/>
    <Route exact path="/admin/bank-transfer" element={ <ProtectedRoute isAuthenticated={true} element={BankTransferListContainer} />}/>
    <Route exact path="/admin/add-bank-transfer" element={ <ProtectedRoute isAuthenticated={true} element={BankTransferPageContainer} />}/>
    <Route exact path="/admin/role-access" element={ <ProtectedRoute isAuthenticated={true} element={RoleAccessPageContainer} />}/>
    <Route exact path="/admin/leagues" element={ <ProtectedRoute isAuthenticated={true} element={LeagueComponentPageContainer} />}/>
    <Route exact path="/admin/create-leagues" element={ <ProtectedRoute isAuthenticated={true} element={LeagueFormPageContainer} />}/>
    <Route exact path="/admin/tournament" element={ <ProtectedRoute isAuthenticated={true} element={TournamentComponentListPageContainer} />}/>
    <Route exact path="/admin/create-tournament" element={ <ProtectedRoute isAuthenticated={true} element={TournamentComponentFormPageContainer} />}/>
    <Route exact path="/admin/players" element={ <ProtectedRoute isAuthenticated={true} element={PlayerListPageContainer} />}/>
    <Route exact path="/admin/create-player" element={ <ProtectedRoute isAuthenticated={true} element={PlayerFormPageContainer} />}/>
    <Route exact path="/admin/rewards" element={ <ProtectedRoute isAuthenticated={true} element={RewardPageContainer} />}/>
    <Route exact path="/admin/create-rewards" element={ <ProtectedRoute isAuthenticated={true} element={RewardPageContainer} />}/>
    <Route exact path="/admin/system-template" element={ <ProtectedRoute isAuthenticated={true} element={SystemTemplateFormPageContainer} />}/>
    <Route exact path="/admin/custom-css" element={ <ProtectedRoute isAuthenticated={true} element={CustomCssFormPageContainer} />}/>
    <Route exact path="/admin/mail" element={ <ProtectedRoute isAuthenticated={true} element={MailPageContainer} />}/>
    <Route exact path="/admin/active-liscence" element={ <ProtectedRoute isAuthenticated={true} element={ActiveLiscencePageContainer} />}/>
    <Route exact path="/admin/one-signal" element={ <ProtectedRoute isAuthenticated={true} element={OneSignalPageContainer} />}/>
    <Route exact path="/admin/email" element={ <ProtectedRoute isAuthenticated={true} element={EmailPageContainer} />}/>
    <Route exact path="/admin/international" element={ <ProtectedRoute isAuthenticated={true} element={InternationalPageContainer} />}/>
    <Route exact path="/admin/match/running-event-match" element={ <ProtectedRoute isAuthenticated={true} element={RunningEventMatchPageContainer} />}/>
    <Route exact path="/admin/match/upcoming-event-match" element={ <ProtectedRoute isAuthenticated={true} element={UpcomingEventMatchPageContainer} />}/>
    <Route exact path="/admin/match/question" element={ <ProtectedRoute isAuthenticated={true} element={MatchQuestionsPageContainer} />}/>
    <Route exact path="/admin/all-bets-log" element={ <ProtectedRoute isAuthenticated={true} element={AllBetsLogPageContainer} />}/>
    <Route exact path="/admin/pending-bets-log" element={ <ProtectedRoute isAuthenticated={true} element={PendingBetsLogPageContainer} />}/>
    <Route exact path="/admin/won-bets-log" element={ <ProtectedRoute isAuthenticated={true} element={WonBetsLogPageContainer} />}/>
    <Route exact path="/admin/lost-bets-log" element={ <ProtectedRoute isAuthenticated={true} element={LostBetsLogPageContainer} />}/>
    <Route exact path="/admin/refunded-bets-log" element={ <ProtectedRoute isAuthenticated={true} element={RefundedBetsLogPageContainer} />}/>
    <Route exact path="/admin/result" element={ <ProtectedRoute isAuthenticated={true} element={ResultsPageContainer} />}/>
    <Route exact path="/admin/who-will-win" element={ <ProtectedRoute isAuthenticated={true} element={WinPageContainer} />}/>
    <Route exact path="/admin/manage-all-users" element={ <ProtectedRoute isAuthenticated={true} element={ManageUsersPageContainer} />}/>
    <Route exact path="/admin/user-detail" element={ <ProtectedRoute isAuthenticated={true} element={UserDetailPageContainer} />}/>
    <Route exact path="/admin/manage-active-users" element={ <ProtectedRoute isAuthenticated={true} element={ManageActiveUsersPageContainer} />}/>
    <Route exact path="/admin/manage-banned-users" element={ <ProtectedRoute isAuthenticated={true} element={ManageBannedUsersPageContainer} />}/>
    <Route exact path="/admin/manage-unverified-users" element={ <ProtectedRoute isAuthenticated={true} element={ManageUnverifiedUsersPageContainer} />}/>
    <Route exact path="/admin/setting" element={ <ProtectedRoute isAuthenticated={true} element={SettingsPageContainer} />}/>
    <Route exact path="/admin/ticket" element={ <ProtectedRoute isAuthenticated={true} element={TicketPageContainer} />}/>
    <Route exact path="/admin/permission" element={ <ProtectedRoute isAuthenticated={true} element={PermissionComponentPageContainer} />}/>
    <Route exact path="/admin/auth-users" element={ <ProtectedRoute isAuthenticated={true} element={AuthUserComponentPageContainer} />}/>
    <Route exact path="/admin/roles" element={ <ProtectedRoute isAuthenticated={true} element={RolesComponentPageContainer} />}/>
    <Route exact path="/admin/deposit-log" element={ <ProtectedRoute isAuthenticated={true} element={DepositLogComponentPageContainer} />}/>
    <Route exact path="/admin/pending-deposit-log" element={ <ProtectedRoute isAuthenticated={true} element={PendingDepositLogComponentPageContainer} />}/>
    <Route exact path="/admin/rejected-deposit-log" element={ <ProtectedRoute isAuthenticated={true} element={RejectedDepositLogComponentPageContainer} />}/>
    <Route exact path="/admin/accepted-deposit-log" element={ <ProtectedRoute isAuthenticated={true} element={AcceptedDepositLogComponentPageContainer} />}/>
    <Route exact path="/admin/app-info" element={ <ProtectedRoute isAuthenticated={true} element={AppInfoPageContainer} />}/>
    <Route exact path="/admin/location" element={ <ProtectedRoute isAuthenticated={true} element={LocationPageContainer} />}/>
    <Route exact path="/admin/send-notification" element={ <ProtectedRoute isAuthenticated={true} element={NotificationPageContainer} />}/>
    <Route exact path="/admin/match/all-event-match" element={ <ProtectedRoute isAuthenticated={true} element={AllEventMatchPageContainer} />}/>
    <Route exact path="/admin/match/featured" element={ <ProtectedRoute isAuthenticated={true} element={SingleEventMatchPageContainer} />}/>
  
  </Routes>

);





