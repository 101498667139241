import  React, { useState } from "react";
import RoleList from './components/List';
import RoleFormDialogue from "./components/Form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faTrash } from '@fortawesome/free-solid-svg-icons';
export default function RolePage() {
    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleEdit = (row) => {
        console.log(`Edit clicked for ID: ${row.id}`);
        setShow(true)
    }
    const handleDelete = (id) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
        if (confirmDelete) {
            console.log(`Deleted ID: ${id}`);
        }
    }
    const columns = [
        {
          name: 'ID',
          selector: row => row.id,
          sortable: true,
        },
        {
          name: 'Name',
          selector: row => row.name,
          sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
              <div>
                <button 
                  onClick={() => handleEdit(row)} 
                  className="btn btn-link"
                  title="Edit"
                >
                  <FontAwesomeIcon icon={faPencilAlt } />
                </button>
                <button 
                  onClick={() => handleDelete(row.id)} 
                  className="btn btn-link text-danger"
                  title="Delete"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ),
          },
      ];
      const data = [
        { id: 1, name: 'Dashboard' },
        { id: 2, name: 'Category' },
        { id: 3, name: 'User' },
        { id: 4, name: 'City' },
        

      ];
    return(
        <>
        <RoleList data={data} columns={columns} activeTab={activeTab} setActiveTab={setActiveTab} handleShow={handleShow}/>
        <RoleFormDialogue show={show} handleClose={handleClose} />
        </>
    )
    
}