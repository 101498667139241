import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Nav,
  CardHeader,
  Form,
  Row,
  Button,
} from "react-bootstrap";
export default function ActiveLiscencePage() {
  const [activeTab, setActiveTab] = useState("okWallet");
  const [activeNestedTab, setActiveNestedTab] = useState("personal");
  const navigate = useNavigate();

  return (
    <>
      <Row>
        <Col
          className="d-flex align-items-center"
          style={{ whiteSpace: "nowwrap" }}
        >
          <span className="text-xl" style={{ fontSize: "150%" }}>
            Active Liscence
          </span>
        </Col>
      </Row>
      <div className="tab-content mt-2">
        <Card className="border-top-color">
          <>
            <CardHeader className="d-flex justify-content-between border-bottom pb-1">
              <div>Liscence</div>
            </CardHeader>

            <CardBody>
              <p>Liscence Activated</p>
              <p><b>Panel: </b>License Activated</p>
              <p><b>License Code: </b>License Activated</p>
              <p><b>Domains: </b>License Activated</p>
              <p><b>Domain Limit: </b>License Activated</p>
              <p><b>Expire Date: </b>License Activated</p>
            </CardBody>
          </>
        </Card>
      </div>
    </>
  );
}
