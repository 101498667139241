import  React, { useState } from "react";
import {  Button,Modal,Form, FormControl, Row } from "react-bootstrap";
import location from '../../../states_titlecase.json';
export default function LocationFormDialogue(props) {
    const { show, handleClose, validate, row, setRow } = props; 
    return (
        <>
        <Modal show={show} onHide={handleClose}>
          <Form onSubmit={(e) => {
            e.preventDefault();
              validate(row);
            }}>
            <Modal.Header closeButton>
              <Modal.Title>Location Block</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group className="mb-3">
                <Form.Label>Location</Form.Label>
                <Form.Select 
                name="statename" 
                id="statename" 
                onChange={(e) => setRow({
                  ...row,
                  statename : e.target.value
                })}>
                {location.map((location) => (
                  <option key={location.abbreviation} value={location.name}>
                    {location.name} ({location.abbreviation})
                  </option>
                ))}
                </Form.Select>
            </Form.Group>
            
              {/* <Form.Group className="mb-3">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                    type="text"
                    name="statename"
                    onChange={(e) => setRow({
                      ...row,
                      statename : e.target.value
                    })}
                  />
              </Form.Group> */}
              {/* <Form.Group className="mb-3">
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control
                    type="text"
                    name="latitude"
                    onChange={(e) => setRow({
                      ...row,
                      latitude : e.target.value
                    })}
                  />
              </Form.Group> */}
              {/* <Form.Group className="mb-3">
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control
                    type="text"
                    name="longitude"
                    onChange={(e) => setRow({
                      ...row,
                      longitude : e.target.value
                    })}
                  />
              </Form.Group> */}
              {/* <Form.Group className="mb-3">
                    <Form.Label>Radius (km)</Form.Label>
                    <Form.Control
                    type="text"
                    name="radiusInKm"
                    onChange={(e) => setRow({
                      ...row,
                      radiusInKm : e.target.value
                    })}
                  />
              </Form.Group> */}
            

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
      </Modal>
        </>
    )
}