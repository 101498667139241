import  React, { useState } from "react";
import RoleAccessList from './components/RoleAccessList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faTrash } from '@fortawesome/free-solid-svg-icons';
export default function RoleAccessPage() {
    const [activeTab, setActiveTab] = useState('all');
    const columns = [
        {
          name: 'ID',
          selector: row => row.id,
          sortable: true,
        },
        {
          name: 'Name',
          selector: row => row.name,
          sortable: true,
        }
    ];
    const data = [
      { id: 1, name: 'Dashboard' },
      { id: 2, name: 'Category' },
      { id: 3, name: 'User' },
      { id: 4, name: 'City' }
    ];
    return(
        <>
        <RoleAccessList data={data} columns={columns} activeTab={activeTab} setActiveTab={setActiveTab}/>
        </>
    )
    
}