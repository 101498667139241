import  React, { useEffect, useState, useRef, version } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { getAllLocation, createLocation } from "../../api/location/locationAction";
import LocationListPage from "./components/List";
import LocationFormDialogue from "./components/Form";
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
export default function LocationPage(props) {

    const dispatch = useDispatch();
    const isFetch = useRef(false);
    const { isSaved } = props;
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [row, setRow] = useState({
      statename : "",
      latitude : 0,
      longitude : 0,
      radiusInKm : 0,
      status: "false",
    });
    const [isChecked, setIsChecked] = useState("false");
   
    const toggleSwitch = (id) => {
      setData((prevData) => {
        const updatedData = prevData.map((item) => {
          if (item.id == id) {
            const updatedVal = { ...item, status: isChecked === "false" ? "true" : "false" };
            dispatch(createLocation(updatedVal));
            return updatedVal;
          }
          return item; 
        });
        return updatedData;
      });
      setIsChecked((prevChecked) => !prevChecked);
    };
    
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    }
    
    const columns = [
        {
          name: 'ID',
          selector: row => row.id,
          sortable: true,
        },
        
        {
          name: 'Location',
          cell: row => row.statename ?? 'N/A',
        },
        {
          name: 'Latitude',
          cell: row => row.latitude ?? 'N/A',
        },
        {
          name: 'Longitude',
          cell: row => row.longitude ?? 'N/A',
        },
        {
          name: 'Radius (km)',
          cell: row => row.radiusInKm ?? 'N/A',
        },
        {
          name: 'Action',
          cell: row => {
            return (
              <Button
              onClick={(e) => {
                console.log("Toggling row with ID:", row.id);
                e.stopPropagation(); 
                toggleSwitch(row.id);
              }}
              className={`d-flex align-items-center ${row.status == "true" || row.status == true ? "btn-success" : "btn-danger"}`}>
                <span className="mr-2">{row.status == "true" || row.status == true ? "Block" : "Unblock"}</span>
              </Button>
            );
          }
          
        }
        
        
    ];
       
    useEffect(()=> {
      if(!isFetch.current) {
        dispatch(getAllLocation());
      }
      isFetch.current = true;
    },[!isFetch.current])

    useEffect(() => {
      if(props.result && Array.isArray(props.result.data)) {
        const updateData  =  props.result.data.map((item, index) => ({
          ...item,
          // s_n: index + 1,
           
        }))
        setData(updateData);
      }
    },[props.result])

    // useEffect(() => {
    //   if(data) {
    //     setData(data);
    //   }
    // }, [data])
     

    const validate = async (row) => {
      const checkData = data && data.some((value,index) => {
        if(value.statename === row.statename) {
          toast.error('Location already exist!');
          return true;
        }
        return false;
      })
      if(!checkData) await dispatch(createLocation(row));
    }

    useEffect(() => {
      if(isSaved && props.result.data) {
        setData(prevData => [...prevData, props.result.data]);
        setShow(false);
        isFetch.current = false;
        setTimeout(() => {
          toast.success('Data save Successfuly!');
        }, 1000);
      }
    },[isSaved,props.result.data])

    return(
        <>
        <LocationListPage data={data} columns={columns} handleShow={handleShow}/>
        <LocationFormDialogue show={show} handleClose={handleClose} validate={validate} row={row} setRow={setRow} />
        </>
    )
    
}

 