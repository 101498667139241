import  React, { useState } from "react";
import { Card, CardBody, Col,  Nav, CardHeader, Form, Row, Button  } from "react-bootstrap";
import DataTableComponent from "../../../../partials/DataTableComponent";
export default function MobailBankForm(props) {
    const {data,columns,activeNestedTab,setActiveNestedTab, activeTab, setActiveTab, handleForm, handleFormPage } = props;
    
    return (
        <>
        <Nav variant="tabs" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)} style={{ display:"-webkit-inline-box" }}>
            <Nav.Item>
                <Nav.Link eventKey="okWallet">Ok Wallet</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="apiMethod">Api Method</Nav.Link>
            </Nav.Item>
        </Nav>
        <div className="tab-content mt-2">
            <Card className="border-top-color">
                {activeTab === 'okWallet' && (
                    <>
                        <Nav variant="tabs" activeKey={activeNestedTab} onSelect={(selectedKey) => setActiveNestedTab(selectedKey)} style={{ display:"-webkit-inline-box" }}>
                            <Nav.Item>
                                <Nav.Link eventKey="personal">Personal</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="merchant">Merchant</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="agent">Agent</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        {activeNestedTab === 'personal'  && (
                            <CardBody>
                                        <Form>
                                            <Row className="d-flex">
                                                <Col xs={6} sm={6} lg={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Select Brand</Form.Label>
                                                        <Form.Select aria-label="Default select example">
                                                            <option value="">Select Brand</option>
                                                            <option value="1">OnHost</option>
                                                            <option value="2">OnHost2</option>
                                                        </Form.Select>
                                                    </Form.Group>  
                                                </Col>
                                                <Col xs={6} sm={6} lg={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Number</Form.Label>
                                                        <Form.Control type="text" placeholder="Your Number" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} sm={6} lg={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Transaction Fee (%) </Form.Label>
                                                        <Form.Control type="number" placeholder="Transaction Fee" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} sm={6} lg={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Min Amount </Form.Label>
                                                        <Form.Control type="number" placeholder="Min Amount" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} sm={6} lg={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Max Amount </Form.Label>
                                                        <Form.Control type="number" placeholder="Max Amount" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} sm={6} lg={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Status</Form.Label>
                                                        <Form.Select aria-label="Default select example">
                                                            <option value="1">Enable</option>
                                                            <option value="2">Disable</option>
                                                        </Form.Select>
                                                    </Form.Group>  
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6} sm={6} lg={6}>
                                                    <Button class="btn btn-xs btn-primary">Submit</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                            </CardBody>
                        )}
                        {activeNestedTab === 'merchant'  && (
                                <CardBody>
                                    <Form>
                                        <Row className="d-flex">
                                            <Col xs={6} sm={6} lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Select Brand</Form.Label>
                                                    <Form.Select aria-label="Default select example">
                                                        <option value="">Select Brand</option>
                                                        <option value="1">OnHost</option>
                                                        <option value="2">OnHost2</option>
                                                    </Form.Select>
                                                </Form.Group>  
                                            </Col>
                                            <Col xs={6} sm={6} lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Number</Form.Label>
                                                    <Form.Control type="text" placeholder="Your Number" />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6} sm={6} lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Transaction Fee (%) </Form.Label>
                                                    <Form.Control type="number" placeholder="Transaction Fee" />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6} sm={6} lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Min Amount </Form.Label>
                                                    <Form.Control type="number" placeholder="Min Amount" />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6} sm={6} lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Max Amount </Form.Label>
                                                    <Form.Control type="number" placeholder="Max Amount" />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6} sm={6} lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select aria-label="Default select example">
                                                        <option value="1">Enable</option>
                                                        <option value="2">Disable</option>
                                                    </Form.Select>
                                                </Form.Group>  
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} sm={6} lg={6}>
                                                <Button class="btn btn-xs btn-primary">Submit</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                        )}
                        {activeNestedTab === 'agent'  && (
                            <CardBody>
                                <Form>
                                    <Row className="d-flex">
                                        <Col xs={6} sm={6} lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Select Brand</Form.Label>
                                                <Form.Select aria-label="Default select example">
                                                    <option value="">Select Brand</option>
                                                    <option value="1">OnHost</option>
                                                    <option value="2">OnHost2</option>
                                                </Form.Select>
                                            </Form.Group>  
                                        </Col>
                                        <Col xs={6} sm={6} lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Number</Form.Label>
                                                <Form.Control type="text" placeholder="Your Number" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} sm={6} lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Transaction Fee (%) </Form.Label>
                                                <Form.Control type="number" placeholder="Transaction Fee" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} sm={6} lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Min Amount </Form.Label>
                                                <Form.Control type="number" placeholder="Min Amount" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} sm={6} lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Max Amount </Form.Label>
                                                <Form.Control type="number" placeholder="Max Amount" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} sm={6} lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Status</Form.Label>
                                                <Form.Select aria-label="Default select example">
                                                    <option value="1">Enable</option>
                                                    <option value="2">Disable</option>
                                                </Form.Select>
                                            </Form.Group>  
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6} sm={6} lg={6}>
                                            <Button class="btn btn-xs btn-primary">Submit</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        )}
                    </>
                
                )}
                {activeTab === 'apiMethod' && (
                    <Col xs={12}>
                            <Card className="border-top-color">
                                <CardHeader className="d-flex">
                                    <h5>Api Method</h5>
                                    <div className="ms-auto">
                                        <Button type="button" size="sm">Add MobileBank (API)</Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                                </CardBody>
                            </Card>
                    </Col>
                )}
            </Card>
        </div>
        </>
    )
}