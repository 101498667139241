import * as actionTypes from "./matchActionType";
import axios from "axios";
const { REACT_APP_CRICKET_URL } = process.env;
const config = {};
export const getAllMatch = () => {
    const data = {};
    return (dispatch) => {
        dispatch(getAllMatchInit());
        axios.get(`${REACT_APP_CRICKET_URL}/tournament/v1/featured`, data,config)
          .then((response) => {
            dispatch(getAllMatchSuccess(response.data));
          })
          .catch(function (error) {
            dispatch(getAllMatchFailure());
          });
      };
}
export const getAllMatchInit = () => ({
    type: actionTypes.GET_MATCH_ALL,
});
export const getAllMatchFailure = () => ({
    type: actionTypes.GET_MATCH_ALL_FAILURE,
});
export const getAllMatchSuccess = (data) => ({
    type: actionTypes.GET_MATCH_ALL_SUCCESS,
    payload: { data },
});