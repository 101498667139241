import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import RunningEventFormDialogue from "./components/Form";
import PendingBetLogList from "./components/List";

export default function PendingBetsLogPage() {
  const [show, setShow] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setImagePreview(null);
    setShow(true);
  };
  const handleEdit = (row) => {
    console.log(`Edit clicked for ID: ${row.id}`);
    setShow(true);
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ID: ${id}?`
    );
    if (confirmDelete) {
      // Proceed with the delete action
      console.log(`Deleted ID: ${id}`);
    }
  };
  
  const data = [
    {
      id: 1,
      name: "Demos",
      userName: "@Demos",
      country1: "India",
      country2: "Afghanistan",
      question: "Who will win?",
      option: "Draw",
      rate: "1 X 2",
      invest: "10 USD",
      return: "0.3 USD",
      status: "pending",
    },
  ];

  const columns = [
    {
      name: "SN",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "USER",
      selector: (row) => (
        <div>
          <div className="mb-1">{row?.name}</div>
          <div>{row?.userName}</div>
        </div>
      ),
    },
    {
      name: "MATCH",
      selector: (row) => (
        <div>
          <div className="my-1">{row?.country1 || "Unknown Country 1"}</div>
          <div className="badge text-bg-info rounded-pill text-white">vs</div>
          <div className="my-1">{row?.country2 || "Unknown Country 2"}</div>
        </div>
      ),
    },
    {
      name: "QUESTION",
      selector: (row) => row.question,
    },
    {
      name: "OPTION",
      selector: (row) => row.option,
    },
    {
      name: "RATE",
      selector: (row) => row.rate,
    },
    {
      name: "INVEST",
      selector: (row) => row.invest,
    },
    {
      name: "RETURN",
      selector: (row) => row.return,
    },
    {
      name: "Status",
      sortable: true,
      cell: (row) => (
        <div>
          <span
            style={{ fontSize: "14px" }}
            className={`badge text-white ${
              row.status === "won"
                ? "text-bg-success"
                : row.status === "pending"
                ? "text-bg-warning"
                : row.status === "lost"
                ? "text-bg-danger"
                : "text-bg-secondary"
            }`}
          >
            {row.status.charAt(0).toUpperCase() + row.status.slice(1)}{" "}
            {/* Capitalizes the first letter */}
          </span>
        </div>
      ),
    },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <PendingBetLogList
        data={data}
        columns={columns}
        handleShow={handleShow}
      />
      <RunningEventFormDialogue
        show={show}
        handleClose={handleClose}
        handleImageChange={handleImageChange}
        imagePreview={imagePreview}
      />
    </>
  );
}
