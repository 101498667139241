import  React, { useState } from "react";
import ChangePasswordForm from './components/Form';
export default function ChangePasswordPage() {
    const data = [
        { id: 1, name: 'John Doe', age: 28, email: 'john@example.com' },
        { id: 2, name: 'Jane Smith', age: 34, email: 'jane@example.com' },
        { id: 3, name: 'Alice Johnson', age: 25, email: 'alice@example.com' },
        { id: 4, name: 'Bob Brown', age: 42, email: 'bob@example.com' },
      ];
    return(
        <>
        <ChangePasswordForm data={data}/>
        </>
    )
    
}