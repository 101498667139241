import TournamentComponentPage from './Tournament.component';
import { connect } from 'react-redux';
import { getAllTournament } from '../../../api/tournament/tournamentAction';
const mapDispatchToProps = {
    getAllTournament
}
const mapStateToProps = state => ({
    isLoading: state.tournamentPage.isLoading,
    isSaved: state.tournamentPage.isSaved,
    result: state.tournamentPage.result,
    metadata: state.tournamentPage.result,
    count: 1,
});
export const TournamentComponentListPageContainer = connect(mapStateToProps, mapDispatchToProps)(TournamentComponentPage); 