import LeagueComponentPage from './League.component';
import { connect } from 'react-redux';
import { getAllLeagueTemplate } from '../../../api/leagueTemplate/leagueTemplateAction';
import { getAllUser } from '../../../api/user/userAction';
const mapDispatchToProps = {
    getAllLeagueTemplate,
    getAllUser
}

const mapStateToProps = state => ({
    isLoading: state.leagueTemplatePage.isLoading,
    isSaved: state.leagueTemplatePage.isSaved,
    result: state.leagueTemplatePage.result,
    metadata: state.leagueTemplatePage.result,
    data: state.userPage.result,
    count: 1,
});
export const LeagueComponentPageContainer = connect(mapStateToProps, mapDispatchToProps)(LeagueComponentPage); 