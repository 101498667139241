import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  CardHeader,
  Form,
  Row,
  Button,
} from "react-bootstrap";
import DataTableComponent from "../../../../partials/DataTableComponent";
export default function PaymentForm(props) {
  const {
    data,
    columns,
    activeNestedTab,
    setActiveNestedTab,
    activeTab,
    setActiveTab,
    handleForm,
    handleFormPage,
  } = props;

  return (
    <>
      
      <div className="tab-content mt-2">
        <Card className="border-top-color">
          <>
            <CardHeader className="d-none justify-content-between border-bottom pb-1">
              <div>Payment</div>
            </CardHeader>

            <CardBody>
              <Form>
                <Row className="d-flex">
                  <Col xs={6} sm={6} lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label><b>Select Brand</b></Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option value="1">onhost</option>
                        <option value="2">onhost2</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={6} sm={6} lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label><b>Status</b></Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={6} sm={6} lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label><b> Transaction Fee (%)</b></Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} sm={6} lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label><b> Min Amount</b></Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} sm={6} lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label><b> Max Amount (BDT)</b></Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} sm={6} lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Sandbox</b>
                      </Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option value="1">Acive</option>
                        <option value="2">Inactive</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={6} sm={6} lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label><b> Exchange Rate (1 USD = ? BDT)</b></Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} sm={6} lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label><b> Extra Fee (BDT)</b></Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} sm={6} lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label><b> Client Id</b></Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} sm={6} lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label><b> Secret Key</b></Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} sm={6} lg={6}>
                    <Button class="btn btn-xs btn-primary">Update</Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </>
        </Card>
      </div>
    </>
  );
}
