import  React,{useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import DepositLogListPage from "./components/List.component";
import DepositLogFormDialogue from "./components/Form";

export default function DepositLogComponentPage() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => {
      setShow(true)
    }
    const handleEdit = (row) => {
      setShow(true)
    }
    const handleDelete = (id) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
        if (confirmDelete) {
            console.log(`Deleted ID: ${id}`);
        }
    }
    const columns = [
      {
        name: 'Sl',
        selector: row => row.id,
        sortable: true,
      },
      {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
      },
      {
        name: 'Gateway Name',
        selector: row => row.gateway,
        sortable: true,
      },
      {
        name: 'Amount',
        selector: row => row.amount,
        sortable: true,
      },
      {
        name: 'Charge',
        selector: row => row.charge,
        sortable: true,
      },
      {
        name: 'USD Amount',
        selector: row => row.usd,
        sortable: true,
      },
      {
        name: "Status",
        sortable: true,
        cell: (row) => (
          <div>
            <span
              style={{ fontSize: "14px" }}
              className={`badge text-white ${
                row.status === "complete"
                  ? "text-bg-success"
                  : row.status === "pending"
                  ? "text-bg-warning"
                  : row.status === "incomplete"
                  ? "text-bg-danger"
                  : "text-bg-secondary"
              }`}
            >
              {row.status.charAt(0).toUpperCase() + row.status.slice(1)}{" "}
              {/* Capitalizes the first letter */}
            </span>
          </div>
        ),
      },
      {
        name: 'Transaction Id',
        selector: row => row.transaction,
        sortable: true,
      },
    ];
    const data = [
      { id: 1, name: 'Demos', gateway: 'PayPal', amount: '10 USD', charge: '1.1 USD', usd:'11.1 USD', status: 'incomplete', transaction: 'DMGCZC2PXJKH' },
      { id: 2, name: 'Demos', gateway: 'PayPal', amount: '10 USD', charge: '1.1 USD', usd:'11.1 USD', status: 'pending', transaction: 'DMGCZC2PXJKH' },
      { id: 3, name: 'Demos', gateway: 'PayPal', amount: '10 USD', charge: '1.1 USD', usd:'11.1 USD', status: 'complete', transaction: 'DMGCZC2PXJKH' },
  ];
  
    return(
        <>
        <DepositLogListPage data={data} columns={columns}  handleShow={handleShow}/>
        <DepositLogFormDialogue
            show={show}
            handleClose={handleClose}
          />
        </>
    )
}