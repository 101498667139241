import  React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import MatchQuestionsList from "./components/List";
import MatchQuestionsFormDialogue from "./components/Form";
export default function MatchQuestionsPage() {
    const [show, setShow] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const navigate = useNavigate()

    const handleNavigate = () =>{
      navigate('/admin/match/question')
    }
    
    const handleClose = () => setShow(false);

    const handleShow = () => {
        setImagePreview(null);
        setShow(true)
    }
    const  handleEdit = (row) => {
      console.log(`Edit clicked for ID: ${row.id}`);
      setShow(true)
    }

    const handleDelete = (id) => {
      const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
      if (confirmDelete) {
        // Proceed with the delete action
        console.log(`Deleted ID: ${id}`);
      }
    }

    // Note: name field is mandatory here. Removing it will crash
    const data = [
      { id: 1,name:'', question: 'Who will win?', optionCount: 1, status: 1 },
      { id: 2,name:'', question: 'Who will win?', optionCount: 1, status: 0 },
    ];
    

    const columns = [
  {
    name: 'SL',
    selector: row => row.id,
    sortable: true,
  },
  {
    name: 'Question',
    selector: row => row.question
  },
  {
    name: 'Option Count',
    selector: row => row.optionCount
  },
  {
    name: 'Status',
    sortable: true,
    cell: row => (
      <div>
        <span style={{ fontSize: '14px' }} className={`badge ${row.status === 1 ? 'text-bg-success' : 'text-bg-danger'}`}>
          {row.status === 1 ? 'Active' : 'Inactive'}
        </span>
      </div>
    ),
  },
  {
    name: 'Action',
    cell: row => (
      <div>
        <button 
          onClick={() => handleEdit(row)} 
          className="btn btn-secondary me-2"
          title="Edit"
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </button>
        <button 
          onClick={() => handleDelete(row.id)} 
          className="btn btn-danger"
          title="Delete"
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    ),
  },
];
      
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        
        }
    }
    return(
        <>
        <MatchQuestionsList data={data} columns={columns} handleShow={handleShow}/>
        <MatchQuestionsFormDialogue show={show} handleClose={handleClose} handleImageChange={handleImageChange} imagePreview={imagePreview} />
        </>
    )
    
}