import  React, { useState } from "react";
import UserList from './components/List';
export default function UserPage() {
    const data = [
        { id: 1, name: 'John Doe', age: 28, email: 'john@example.com' },
        { id: 2, name: 'Jane Smith', age: 34, email: 'jane@example.com' },
        { id: 3, name: 'Alice Johnson', age: 25, email: 'alice@example.com' },
        { id: 4, name: 'Bob Brown', age: 42, email: 'bob@example.com' },
      ];
    const columns = [
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Age',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email ,
        sortable: true,
    },
    ];
    return(
        <>
        <UserList data={data} columns={columns}/>
        </>
    )
    
}