import  React, { useState } from "react";
import { Card, CardBody, Col,  Nav, CardHeader  } from "react-bootstrap";
import DataTableComponent from "../../../partials/DataTableComponent";
export default function PaymentList(props) {
    const {data,columns, activeTab, setActiveTab } = props;
    return (
        <>
        <Nav variant="tabs" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)} style={{ display:"-webkit-inline-box" }}>
            <Nav.Item>
                <Nav.Link eventKey="all">All</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="completed">Completed</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="pending">Pending</Nav.Link>
            </Nav.Item>
        </Nav>
        <div className="tab-content mt-2">
        {activeTab === 'all' && (
            <Col xs={12}>
                <Card className="border-top-color">
                    <CardHeader>
                        <h5>Payments</h5>
                    </CardHeader>
                    <CardBody>
                    <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                    </CardBody>
                </Card>
            </Col>
        
        )}
        {activeTab === 'completed' && (
        <Col xs={12}>
                <Card className="border-top-color">
                    <CardHeader>
                        <h5>Trash payments</h5>
                    </CardHeader>
                    <CardBody>
                    <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                    </CardBody>
                </Card>
            </Col>
        )}
        {activeTab === 'pending' && (
        <Col xs={12}>
            <Card className="border-top-color">
                <CardHeader>
                    <h5>Pending payments</h5>
                </CardHeader>
                <CardBody>
                <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                </CardBody>
            </Card>
            </Col>
        )}
        </div>
        </>
    )
}