import  React, { useState } from "react";
import { Card, CardBody, Col, Nav, CardHeader, Button  } from "react-bootstrap";
import DataTableComponent from "../../../partials/DataTableComponent";
export default function RoleList(props) {
    const { data, columns, activeTab,setActiveTab, handleShow } = props;
    return (
        <>
        <Col xs={12}>
            <Card>
                <CardHeader className="d-flex">
                    <h5>Role</h5>
                    <div className="ms-auto">
                       <Button type="button"  onClick={handleShow} size="sm">Add Role</Button>
                    </div>
                </CardHeader>
                <CardBody>
                <DataTableComponent data={data} columns={columns} perPage={10} pageOption={[2, 4, 6]} />
                </CardBody>
            </Card>
        </Col>
        </>
    )
}