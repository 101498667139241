import React, { useState, useEffect } from "react";
import { RoutePath  } from "../route";
import HeaderPage from "../partials/Header";
import { Container } from "react-bootstrap";

import FooterPage from "../partials/Footer";
const { REACT_APP_URL } = process.env;
export default function AppRoot() {
    
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    
    useEffect(() => {
        setIsAuthenticated(localStorage.getItem('isAuthenticated'));
    },[!isAuthenticated]);
     
    // useEffect(() => {
    //     const fetchData = async () => {
    //       try {
    //         const response = await fetch(REACT_APP_URL+'/admin/api/info');
    //         if (!response.ok) {
    //           throw new Error('Network response was not ok');
    //         }
    //         const result = await response.json();
    //         setData(result); 
            
    //       } catch (error) {
    //         setError(error.message); 
    //       }
    //     };
    //     fetchData(); 
    // }, []);
      
   return (
    <>
     

    {isAuthenticated ? <HeaderPage /> : null}
        <Container className="mt-100">
            <RoutePath/>
        {/* <FooterPage data={data}/> */}
        </Container>
    </>
    );
}