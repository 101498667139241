import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faQuestion } from "@fortawesome/free-solid-svg-icons";
import AllEventMatchList from "./components/List";
import { useNavigate } from "react-router-dom";
import { getAllMatch } from "../../../api/match/matchAction";
import { useDispatch } from "react-redux";

export default function AllEventMatchPage(props) {
  
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [row, setRow] = useState({});
  
  const [imagePreview, setImagePreview] = useState(null);
  const isFetch = useRef(false);
  const navigate = useNavigate();

  

  const handleNavigate = () => {
    navigate("/admin/match/question");
  };
  
  const handleShow = () => {
    setImagePreview(null);
   //setShow(true);
  };
  const handleEdit = (row) => {
    const singleRow = data && data.find((val, index) => {
      if (index === row.key) {
        setRow(val);   
        return true;    
      }
      return false;  
    });
    navigate("/admin/match/featured", { state: { row } }); 
  }

  
  const columns = [
    {
      name: "S.No",
      selector: (row) => row.s_n,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Tournament",
      selector: (row) => row.tournament.name,
      sortable: true,
    },
    {
      name: "Teams",
      selector: (row) => row.teams,
      sortable: true,
    },
    {
      name: "Venue",
      selector: (row) => row.venue.city,
      sortable: true,
    },

    // {
    //   name: 'name',
    //   selector: row => (
    //     <div style={{ textAlign: 'center' }}>
    //       <div className="my-1">{row?.name || 'Unknown Team 1'}</div>
    //       <div className="badge text-bg-info rounded-pill text-white">vs</div>
    //       <div className="my-1">{row?.name2 || 'Unknown Team 2'}</div>
    //     </div>
    //   ),
    // },
    // {
    //   name: 'Start Date & Time',
    //   selector: row => (
    //     <div style={{ textAlign: 'center' }}>
    //       <div className="mb-1">{row?.start}</div>
    //       <div>{row?.endTime}</div>
    //     </div>
    //   ),
    //   sortable: true,
    // },
    // {
    //   name: 'Venue',
    //   selector: row => (
    //     <div style={{ textAlign: 'center' }}>
    //       <div className="mb-1">{row?.venue}</div>
    //       <div>{row?.endTime}</div>
    //     </div>
    //   ),
    //   sortable: true,
    // },
    // {
    //   name: 'Winner',
    //   selector: row => (
    //     <div style={{ textAlign: 'center' }}>
    //       <div className="mb-1">{row?.winner}</div>
    //       <div>{row?.endTime}</div>
    //     </div>
    //   ),
    //   sortable: true,
    // },
    // {
    //   name: 'Toss Winner',
    //   selector: row => (
    //     <div style={{ textAlign: 'center' }}>
    //       <div className="mb-1">{row?.toss_win}</div>
    //       <div>{row?.endTime}</div>
    //     </div>
    //   ),
    //   sortable: true,
    // },
    // {
    //   name: 'Status',
    //   sortable: true,
    //   cell: row => (
    //     <div>
    //       <span style={{ fontSize: '14px' }} className={`badge ${row.status === 1 ? 'text-bg-success' : 'text-bg-danger'}`}>
    //         {row.status === 1 ? 'Completed' : 'Not Completed'}
    //       </span>
    //     </div>
    //   ),
    // },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleEdit(row)}
            className="btn btn-secondary me-2"
            title="Edit"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            onClick={() => handleNavigate()}
            className="btn btn-info text-white"
            title="Delete"
          >
            <FontAwesomeIcon icon={faQuestion} />
          </button>
        </div>
      ),
    },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  
  useEffect(()=> {
    if(!isFetch.current && data) {
      dispatch(getAllMatch());
      setData(data)
      isFetch.current = true;
    }
  },[])


  useEffect(() => {
    if (props.result && Array.isArray(props.result.data)) {
      const updatedData =
        props.result &&
        props.result.data.map((item, index) => ({
          ...item,
          s_n: index + 1,
          teams: Object.values(item.teams)
            .map((item) => item.name)
            .join(" | "),
        }));
      setData(updatedData);
    }
  }, [props.result]);

  return (
    <>
      <AllEventMatchList
        data={data}
        columns={columns}
        handleShow={handleShow}
      />
      {/* <AllEventFormDialogue
        show={show}
        row={row}
        handleClose={handleClose}
        handleImageChange={handleImageChange}
        imagePreview={imagePreview}
        addTeam={addTeam}
        removeTeam={removeTeam}
        setTeams={setTeams}
        teams={teams}
      /> */}
    </>
  );
}
