import React, { useState } from "react";
import { Card, CardBody, Col, Nav, CardHeader, Button } from "react-bootstrap";
import DataTableComponent from "../../../partials/DataTableComponent";
import PaymentForm from "./PaymentForm";

export default function TicketList(props) {
    const { data, columns, handleShow } = props;

    // Set default activeTab to 'paypal' or any other tab you want as default
    const [activeTab, setActiveTab] = useState('all');

    return (
        <>
            <Nav
                variant="pills"
                activeKey={activeTab}
                onSelect={(selectedKey) => setActiveTab(selectedKey)}
                style={{ display: "flex",gap:'.5rem' }}
            >
                <Nav.Item>
                    <Nav.Link
                        eventKey="all"
                        className={`btn navLink ${activeTab === 'all' ? 'btn-primary' : 'btn-outline-primary'}`}
                    >
                        All
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        eventKey="open"
                        className={`btn navLink ${activeTab === 'open' ? 'btn-primary' : 'btn-outline-primary'}`}
                    >
                        Open
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        eventKey="on-hold"
                        className={`btn navLink ${activeTab === 'on-hold' ? 'btn-primary' : 'btn-outline-primary'}`}
                    >
                        On Hold
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        eventKey="closed"
                        className={`btn navLink ${activeTab === 'closed' ? 'btn-primary' : 'btn-outline-primary'}`}
                    >
                        Closed
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        eventKey="trashed"
                        className={`btn navLink ${activeTab === 'trashed' ? 'btn-primary' : 'btn-outline-primary'}`}
                    >
                        Trashed
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="tab-content mt-2">
                {activeTab === 'all' && (
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                            </CardBody>
                        </Card>
                    </Col>
                )}
                {activeTab === 'open' && (
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                            </CardBody>
                        </Card>
                    </Col>
                )}
                {activeTab === 'on-hold' && (
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                            </CardBody>
                        </Card>
                    </Col>
                )}
                {activeTab === 'closed' && (
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                            </CardBody>
                        </Card>
                    </Col>
                )}
                {activeTab === 'trashed' && (
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </div>
        </>
    );
}
