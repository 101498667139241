import { connect } from 'react-redux';
import { getAllLocation } from '../../api/location/locationAction';
import LocationPage from './Location.component';
const mapDispatchToProps = {
    getAllLocation
}
const mapStateToProps = state => ({
    isLoading: state.locationPage.isLoading,
    isSaved: state.locationPage.isSaved,
    result: state.locationPage.result,
    metadata: state.locationPage.result,
    count: 1,
});
export const LocationPageContainer = connect(mapStateToProps, mapDispatchToProps)(LocationPage); 