import * as actionTypes from "./tournamentActionType";
import axios from "axios";
const { REACT_APP_CRICKET_URL } = process.env;
const config = {};
export const getAllTournament = () => {
    const data = {};
    return (dispatch) => {
        dispatch(getAllInit());
        axios.get(`${REACT_APP_CRICKET_URL}/admin/v1/tournamentlist`, data,config)
          .then((response) => {
            dispatch(getAllSuccess(response.data));
          })
          .catch(function (error) {
            dispatch(getAllFailure());
          });
      };
}
export const getAllInit = () => ({
    type: actionTypes.GET_ALL,
});
export const getAllFailure = () => ({
    type: actionTypes.GET_ALL_FAILURE,
});
export const getAllSuccess = (data) => ({
    type: actionTypes.GET_ALL_SUCCESS,
    payload: { data },
});

export const updateTournament = (data) => {
  const headers = {
    'status': data.status ,            
    'tournamentId': data.key, 
  };
  return (dispatch) => {
      dispatch(getUpdateTournamentInit());
      axios.post(`${REACT_APP_CRICKET_URL}/admin/v4/tournamentstausupdate`, {}, { headers: headers } )
        .then((response) => {
          dispatch(getUpdateTournamentSuccess(response.data));
          
        })
        .catch(function (error) {
          dispatch(getUpdateTournamentFailure());
        });
    };
}

export const updateTournament1 = (data) => {
  return (dispatch) => {
    const headers = {
      'status': data.status ,            
      'tournamentId': data.key, 
    };
    
    return axios.post(`${REACT_APP_CRICKET_URL}/admin/v4/tournamentstausupdate`, {}, { headers: headers })
      .then((response) => {
        dispatch(getUpdateTournamentSuccess(response.data));
        return response.data; 
      })
      .catch((error) => {
        dispatch(getUpdateTournamentFailure());
        throw error; 
      });
  };
};


export const getUpdateTournamentInit = () => ({
  type: actionTypes.UPDATE_TOURNAMENT,
});
export const getUpdateTournamentFailure = () => ({
  type: actionTypes.UPDATE_TOURNAMENT_FAILURE,
});
export const getUpdateTournamentSuccess = (data) => ({
  type: actionTypes.UPDATE_TOURNAMENT_SUCCESS,
  payload: { data },
});