import  React, { useState } from "react";
import {  Button,Modal,Form, FormControl, Row } from "react-bootstrap";
export default function BannerFormDialogue(props) {
    const { show, handleClose,handleImageChange, imagePreview } = props; 
    return (
        <>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
                <Form.Label><small>Image (PNG format is standard)</small></Form.Label>
                <FormControl type="file" name="image" id="image" onChange={(e) => handleImageChange(e)}></FormControl>
            </Form.Group>
        </Form>
        <Row>
          <div id="imageContain">
          <img
            id="image-preview"
            className="img-fluid"
            src={imagePreview ? imagePreview : "https://bettpro.s7template.com/public/images/no-image.png"}
            alt="Preview"
          />
          </div>
        </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    )
}