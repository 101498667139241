import * as actionType from "./tournamentActionType";

const initialState = {
    isLoading: false,
    isSaved: false,
    result: [],
    metadata : [],
    count:0
};
export const tournamentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_ALL:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.GET_ALL_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.GET_ALL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                result: action?.payload?.data || [],
                metadata: action?.payload?.metadata || [],
            };
        case actionType.UPDATE_TOURNAMENT:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.UPDATE_TOURNAMENT_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.UPDATE_TOURNAMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        default: 
            return state;
    }
}