import  React, { useState } from "react";
import { Card, CardBody, Col, Nav, CardHeader, Button  } from "react-bootstrap";
import DataTableComponent from "../../../../partials/DataTableComponent";
import { useNavigate } from "react-router-dom";

export default function MatchQuestionsList(props) {
    const { data,columns,  handleShow } = props;
    const navigate = useNavigate()
    const goBack = () =>{
        navigate(-1)
    }
    return (
        <>
        <Col xs={12}>
            <Card>
                <CardHeader className="d-flex">
                    <h5>Question for - vs</h5>
                    <div className="ms-auto">
                       <Button type="button" className="me-2 btn-secondary"  onClick={goBack} size="sm">Go Back</Button>
                       <Button type="button"  onClick={handleShow} size="sm">+ Add New</Button>
                    </div>
                </CardHeader>
                <CardBody>
                <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                </CardBody>
            </Card>
        </Col>
        </>
    )
}