import  React,{useEffect, useState, useRef} from "react";
import LeagueListPage from "./components/List.component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import LeagueFormDialogue from "./components/Form";
import { useDispatch, useSelector  } from "react-redux";
import { getAllLeagueTemplate } from "../../../api/leagueTemplate/leagueTemplateAction";
import LeagueDrawerComponent from "./components/Drawer";
import { getAllUser } from "../../../api/user/userAction";
import { createLeagueTemplate } from "../../../api/leagueTemplate/leagueTemplateAction";
import { toast } from 'react-toastify';

export default function LeagueComponentPage(props) {
  
    //const navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [singleRowData,setSingleRowData] = useState({})

    const allUsers = useSelector((state) => state?.userPage?.result?.data);
     
    const [row, setRow] = useState({
      totalParticipants : 0,
      topLevelParticipants : 0,
      totalPrizePool : 0,
      status : "ACTIVE",
      allowauto : "ACTIVE",
      marginPercentage : 0,
      user_id : "",
      entryfees : 0,
      levelPercentages : [],
      levelparticipants : []
    });
    const handleClose = () => setShow(false);
    const isFetch = useRef(false);
    const handleShow = async() => {
      await dispatch(getAllUser());
       setShow(true)
    }
    const handleEdit = (row) => {
      setShow(true)
    }
    const handleDelete = (id) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
        if (confirmDelete) {
            console.log(`Deleted ID: ${id}`);
        }
    }

    // drawer
   
    const toggleDrawer = (id) => {
       
        setSingleRowData(id)
        setIsOpen(!isOpen);
    };
    
    const columns = [
      {
        name: 'S.No',
        selector: row => row?.s_n,
        sortable: true,
      },
      {
        name: 'Total Participants',
        selector: row => row?.totalParticipants ?? 'N/A',
        sortable: true,
      },
      {
        name: 'Level Percentages',
        selector: row => row?.levelPercentages[0] ?? '0',
        sortable: true,
      },
      {
        name: 'Top Level Participants',
        selector: row => row?.topLevelParticipants ?? 'N/A',
        sortable: true,
      },
      // {
      //   name: 'Total Prize Pool',
      //   selector: row => row?.totalPrizePool ?? 'N/A',
      //   sortable: true,
      // },
      {
        name: 'Status',
        selector: row => row?.status ?? 'N/A',
        sortable: true,
      },
      // {
      //   name: 'Allow Auto',
      //   selector: row => row?.allowauto ?? 'N/A',
      //   sortable: true,
      // },
      {
        name: 'Margin Percentage',
        selector: row => row?.marginPercentage?? 'N/A',
        sortable: true,
      },
      // {
      //   name: 'user',
      //   selector: row => row?.user_id ?? 'N/A',
      //   sortable: true,
      // },
      {
        name: 'Entry Fees',
        selector: row => row?.entryfees ?? 'N/A',
        sortable: true,
      },
      {
        name: 'Action',
        width: '100px',
        cell: row => (
          <div className="d-flex gap-2">
            {/* <button 
              onClick={() => handleEdit(row)} 
              className="btn btn-secondary"
              title="Edit"
            >
              <FontAwesomeIcon icon={faPencilAlt } />
            </button> */}
            <button 
              onClick={() => toggleDrawer(row)} 
              className="btn btn-info text-white"
              title="Edit"
            >
              <FontAwesomeIcon icon={faEye } />
            </button>
          </div>
        ),
      },
    ];

    const validate = (row) => {
      dispatch(createLeagueTemplate(row));
      setShow(false);
    }

    useEffect(() => {
      if(allUsers && allUsers.length > 0) {
        setUsers(allUsers);
        console.warn('all::',users);
      }
    },[allUsers])

    useEffect(()=> {
       
      if(!isFetch.current && data) {
        dispatch(getAllLeagueTemplate());
        setData(data)
        isFetch.current = true;
      }
    },[])

    

    useEffect(() => {
      
      if(props.result && Array.isArray(props.result.data)) {
        const updateData  =  props.result.data.map((item, index) => ({
          ...item,
          s_n: index + 1,
           
        }))
        setData(updateData);
      }
    },[props.result])

    useEffect(() => {
        if(props.isSaved) {
          dispatch(getAllLeagueTemplate());
          setData(data)
          setTimeout(() => {
            toast.success('Data save Successfuly!');
          }, 1000);
        }
    },[props.isSaved])

    



    
    return(
        <>
        <LeagueListPage data={data} columns={columns}  handleShow={handleShow}/>
        <LeagueFormDialogue
            show={show}
            handleClose={handleClose}
            setRow={setRow} 
            row={row}
            users={users}
            validate={validate}
          />
          <LeagueDrawerComponent singleRowData={singleRowData} isOpen={isOpen}  toggleDrawer={toggleDrawer} />
        </>
    )
}