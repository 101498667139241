import  React, { useState } from "react";
import { Card, CardBody, Col, Nav, CardHeader, Button, FormGroup, FormLabel, FormControl, Row  } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function ChangePasswordForm(props) {
    const { data } = props;
    return (
        <Col xs={12}>
        <Card>
            <CardHeader className="d-flex">
                <h5>Change Password</h5>
            </CardHeader>
            <CardBody>
                <Row className="d-flex">
                    <Col lg={6} xs={6} sm={6}>
                        <FormGroup>
                            <FormLabel>Old Password</FormLabel>
                            <FormControl name="oldPassword" id="oldPassword"></FormControl>
                        </FormGroup>
                    </Col>
                    <Col lg={6} xs={6} sm={6}>
                        <FormGroup>
                            <FormLabel>New Password</FormLabel>
                            <FormControl name="newPassword" id="newPassword"></FormControl>
                        </FormGroup>
                    </Col>
                    <Col lg={6} xs={6} sm={6}>
                        <FormGroup>
                            <FormLabel>Confirm New Password</FormLabel>
                            <FormControl name="confirmNewPassword" id="confirmNewPassword"></FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={6} xs={6} sm={6}>
                        <Button className="btn btn-xs btn-primary">Change Password</Button>    
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </Col>
    )
}