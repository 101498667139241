import  React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import TournamentListPage from './components/List.component';
import TournamentFormDialogue from "./components/Form";
import { getAllTournament,updateTournament } from "../../../api/tournament/tournamentAction";
import { useDispatch } from "react-redux";
import { format } from 'date-fns';
import { toast } from 'react-toastify';

export default function TournamentComponentPage(props) {
 
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const [row, setRow] = useState({});
    const handleClose = () => setShow(false);
    const isFetch = useRef(false);
    const handleShow = () => {
      setShow(true)
    }
    const handleEdit = (row) => {
      setRow(row);
      setShow(true)
    }
    const columns = [
      {
        name: 'S.No',
        selector: row => row?.s_n,
        sortable: true,
        width: '90px',
      },
      {
        name: 'Name',
        selector: row => row?.name,
        sortable: true,
      },
      {
        name: 'Start Date',
        selector: row => row?.start_date,
        sortable: true,
        
      },
      {
        name: 'Last Schedule Match Date',
        selector: row => row?.last_scheduled_match_date,
        sortable: true,
         
      },
      // {
      //   name: 'Country',
      //   selector: row => row.countries,
      //   sortable: true,
      // },
      {
        name: 'Status',
        sortable: true,
        width: '90px',
        cell: row => (
          <div>
            <span style={{ fontSize: '14px' }} className={`badge text-white ${row.status === 'ACTIVE' ? 'text-bg-success' : 'text-bg-danger'}`}>
              {row.status === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE'}
            </span>
          </div>
        ),
      },
      {
          name: 'Action',
          width: '100px',
          cell: row => (
            <div>
              <button 
                onClick={() => handleEdit(row)} 
                className="btn btn-secondary"
                title="Edit"
              >
                <FontAwesomeIcon icon={faPencilAlt } />
              </button>
            </div>
          ),
        },
    ];

    const validate = (data,row) => {
      if(row) {
        
        dispatch(updateTournament(row))
        toast.success('Data save Successfuly!');
        // do update
      } else {
        // do create
      }
      setShow(false);
    }
    
    useEffect(()=> {
      if(data) {
        dispatch(getAllTournament());
        setData(data)
        isFetch.current = true;
      }
    },[!isFetch])

   

    useEffect(() => {
      if (props.result && Array.isArray(props.result.data)) {
       const updateData  =  props.result.data.map((item, index) => ({
          ...item,
          s_n: index + 1,
          start_date : item.start_date ?  format(new Date(item.start_date), 'yyyy-MM-dd') : '',
          last_scheduled_match_date : item.last_scheduled_match_date ?  format(new Date(item.last_scheduled_match_date), 'yyyy-MM-dd') : ''
        }))
        setData(updateData);
      }
    },[props.result]);

    return(
        <>
            <TournamentListPage data={data} columns={columns}  handleShow={handleShow}/>
            <TournamentFormDialogue
            show={show}
            row={row}
            data={data}
            setRow={setRow}
            validate={validate}
            handleClose={handleClose}
          />
        </>
    )
}