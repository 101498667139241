import React, { useState } from "react";
import { Button, Modal, Form, FormControl, Row, Col } from "react-bootstrap";

export default function PendingDepositLogFormDialogue(props) {
  const { show, handleClose, handleImageChange, imagePreview } = props;
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Receipt Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <img src="https://acodez.in/wp-content/uploads/2021/01/Characteristics-of-web-services.jpg" className="img-fluid" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
