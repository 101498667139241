import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import TicketList from "./components/List";
import TicketFormDialogue from "./components/Form";

export default function TicketPage() {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEdit = (row) => {
    console.log(`Edit clicked for ID: ${row.id}`);
    setShow(true);
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ID: ${id}?`
    );
    if (confirmDelete) {
      console.log(`Deleted ID: ${id}`);
    }
  };

  const toggleStatus = (index) => {
    const updatedData = [...data];
    updatedData[index].status = updatedData[index].status === "active" ? "inactive" : "active";
    setData(updatedData);
  };

  const columns = [
    {
      name: "Sl",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Ticket Id",
      selector: (row) => row.ticketId,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.username,
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: "Raised Time",
      selector: (row) => row.time,
      sortable: true,
    },
    {
      name: "Status",
      sortable: true,
      cell: (row) => (
        <div>
          <span
            style={{ fontSize: "14px" }}
            className={`badge text-white ${
              row.status === "opened"
                ? "text-bg-warning"
                : "text-bg-danger"
            }`}
          >
            {row.status}
          </span>
        </div>
      ),
    },
    // {
    //   name: "Status",
    //   sortable: true,
    //   cell: (row, rowIndex) => (
    //     <div>
    //       <div className="form-check form-switch d-flex align-items-center gap-2">
    //         <input
    //          style={{fontSize:'1rem'}}
    //           className="form-check-input"
    //           type="checkbox"
    //           role="switch"
    //           id={`statusToggle-${rowIndex}`}
    //           checked={row.status === "active"}
    //           onChange={() => toggleStatus(rowIndex)}
    //         />
    //         <label className="form-check-label" htmlFor={`statusToggle-${rowIndex}`}>
    //           {row.status}
    //         </label>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleEdit(row)}
            className="btn btn-sm btn-primary"
            title="Edit"
          >
            View
          </button>
        </div>
      ),
    },
  ];

  const [data, setData] = useState([
    {
      id: 1,
      name: "",
      ticketId: 'C93CCA65',
      username: "test",
      subject: "testing",
      time: "August 31st, 2024",
      priority: "high",
      status: "opened",
    },
  ]);

  return (
    <>
      <TicketList
        data={data}
        columns={columns}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleShow={handleShow}
      />
      <TicketFormDialogue show={show} handleClose={handleClose} />
    </>
  );
}
