import { connect } from 'react-redux';
import { getAllAppInfo,createAppInfo } from '../../api/appInfo/appInfoAction';
import AppInfoPage from './AppInfo.component';
const mapDispatchToProps = {
    getAllAppInfo,createAppInfo
}
const mapStateToProps = state => ({
    isLoading: state.appInfo.isLoading,
    isSaved: state.appInfo.isSaved,
    result: state.appInfo.result,
    metadata: state.appInfo.metadata,
    count: 1,
});
export const AppInfoPageContainer = connect(mapStateToProps, mapDispatchToProps)(AppInfoPage); 