import * as actionType from "./appInfoActionType";

const initialState = {
    isLoading: false,
    isSaved: false,
    result: [],
    metadata : [],
    count:0
};
export const appInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_ALL_APP_INFO_INIT:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.GET_ALL_APP_INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.GET_ALL_APP_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                result: action?.payload?.data || [],
                metadata: action?.payload?.metadata || [],
            };

        case actionType.CREATE_APP_INFO_INIT:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.CREATE_APP_INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                isSaved: false,
            };
        case actionType.CREATE_APP_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
                result: action?.payload?.data || [],
                metadata: action?.payload?.metadata || [],
            };
        default: 
            return state;
    }
}