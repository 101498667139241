import  React, { useEffect, useState, useRef, version } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { getAllAppInfo,createAppInfo } from "../../api/appInfo/appInfoAction";
import AppInfoList from "./components/List";
import AppInfoFormDialogue from "./components/Form";
import { toast } from 'react-toastify';

export default function AppInfoPage(props) {
    const { isSaved } = props;
    const dispatch = useDispatch();
    const isFetch = useRef(false);
    const [data, setData] = useState([{}]);
    const [row, setRow] = useState(
      {
        id : "",
        versionios : "",
        versionandroid : "",
        build : "",
        buildDate : ""
      }
    );
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
      // data[0].buildDate = data[0].buildDate ? convertYearformat(data[0].buildDate) : ""; 
      // console.warn(data[0].buildDate);
      setRow(data[0]);
      setShow(true)
    }
    
    const columns = [
        {
          name: 'ID',
          cell: row => row.id ?? row.id ,
          sortable: true,
        },
        {
          name: 'Version IOS',
          cell: row => row.versionIOS ??  row.versionios,
        },
        {
          name: 'Version Android',
          cell: row => row.versionAndriod ?? row.versionandroid,
        },
        {
          name: 'Build',
          cell: row => row.build ?? 'N/A',
        },
        {
          name: 'Build Date',
          cell: row => row.buildDate ? row.buildDate : 'N/A',
        }
         
      ];

      const convertYearformat = (date) => {
        if (!date) return '';
        const [month, day, year] = date.split('-');
        return `${year}-${day}-${month}`;  // Return in YYYY-MM-DD format
      };

    function formatDate(date) {
      if (!date) return '';
      const [year, month, day] = date.split('-');  
      return `${day}-${month}-${year}`;
    }
    
    useEffect(()=> {
      dispatch(getAllAppInfo());
      setData(data)
      isFetch.current = true;
    },[!isFetch.current])

    useEffect(() => {
      if(props.result) {
        const updateData = { ...props.result.data, s_n:  1, };
        setData([updateData]);
      }
    },[props.result])

    const validate = async (row) => {
      if(row.buildDate) row.buildDate = formatDate(row.buildDate);
      console.warn(row.buildDate);
      await dispatch(createAppInfo(row))
    }

    useEffect(() => {
      if(isSaved) {
        setTimeout(() => {
          setData([props.result.data])
          toast.success('Data save Successfuly!');
          setShow(false);
        }, 1000);
      }
      
    },[isSaved])

    return(
        <>
        <AppInfoList data={data} columns={columns} handleShow={handleShow}/>
        <AppInfoFormDialogue show={show} handleClose={handleClose} validate={validate} setRow={setRow} row={row} data={data}  />
        </>
    )
    
}