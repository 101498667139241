import * as actionTypes from "./locationActionType";
import axios from "axios";
const { REACT_APP_CRICKET_URL } = process.env;
const config = {};
export const getAllLocation = () => {
    const data = {};
    return (dispatch) => {
        dispatch(getAllInit());
        axios.get(`${REACT_APP_CRICKET_URL}/geo/blockgeolist`, data,config)
          .then((response) => {
            dispatch(getAllSuccess(response.data));
          })
          .catch(function (error) {
            dispatch(getAllFailure());
          });
      };
}
export const getAllInit = () => ({
    type: actionTypes.GET_ALL,
});
export const getAllFailure = () => ({
    type: actionTypes.GET_ALL_FAILURE,
});
export const getAllSuccess = (data) => ({
    type: actionTypes.GET_ALL_SUCCESS,
    payload: { data },
});

export const createLocation = (data) => {
  const headers = {
    'latitude': data.latitude ,            
    'longitude': data.longitude,
    'radiusInKm': data.radiusInKm,
    'statename': data.statename,
    'status': data.status !== undefined && data.status !== null ? data.status : false,
    'id': data.id !== undefined && data.id !== null ? data.id : null
  };

  return (dispatch) => {
      dispatch(createLocationInit());
      axios.post(`${REACT_APP_CRICKET_URL}/geo/blockgeo`, {}, { headers: headers } )
        .then((response) => {
          dispatch(createLocationSuccess(response.data));
        })
        .catch(function (error) {
          dispatch(createLocationFailure());
        });
    };
}

 


export const createLocationInit = () => ({
  type: actionTypes.CREATE_LOCATION,
});
export const createLocationFailure = () => ({
  type: actionTypes.CREATE_LOCATION_FAILURE,
});
export const createLocationSuccess = (data) => ({
  type: actionTypes.CREATE_LOCATION_SUCCESS,
  payload: { data },
});