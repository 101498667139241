import  React, { useEffect, useState, useRef } from "react";
import BannerList from './components/List';
import BannerFormDialogue from "./components/Form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { getAllBanner } from "../../api/banner/bannerAction";

export default function BannerPage(props) {

    const dispatch = useDispatch();
    const isFetch = useRef(false);
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [imagePreview, setImagePreview] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setImagePreview(null);
        setShow(true)
    }
    const  handleEdit = (row) => {
      console.log(`Edit clicked for ID: ${row.id}`);
      setShow(true)
    }

    const handleDelete = (id) => {
      const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
      if (confirmDelete) {
        console.log(`Deleted ID: ${id}`);
      }
    }
     
    const columns = [
        {
          name: 'ID',
          selector: row => row.s_n,
          sortable: true,
        },
        
        {
          name: 'name',
          cell: row => row.banner_name,
        },
        {
          name: 'Image',
          cell: (row) => {
            if (row.banner) {
              const base64String = `data:image/png;base64,${row.banner}`;
              return  <img  src={base64String} width="50" />
            } else {
              return <span>N/A</span>;
            }
            
          },
        },
        {
          name: 'status',
          cell: row => row.banner_status ? 'ACTIVE' : 'INACTIVE',
        },
        {
          name: 'Action',
          cell: row => (
            <div>
              <button 
                onClick={() => handleEdit(row)} 
                className="btn btn-link"
                title="Edit"
              >
                <FontAwesomeIcon icon={faPencilAlt } />
              </button>
              <button 
                onClick={() => handleDelete(row.id)} 
                className="btn btn-link text-danger"
                title="Delete"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          ),
        },
      ];
       
    

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        
        }
    }

    useEffect(()=> {
      dispatch(getAllBanner());
      setData(data)
      isFetch.current = true;
    },[!isFetch.current])

    useEffect(() => {
      if(props.result && Array.isArray(props.result.data) && isFetch.current) {
        const updateData  =  props.result.data.map((item, index) => ({
          ...item,
          s_n: index + 1,
           
        }))
        setData(updateData);
      }
    },[props.result,isFetch.current])

    const base64ToBlobUrl = (base64) => {
      if (!base64.startsWith('data:image')) {
        base64 = `data:image/png;base64,${base64}`;
      }
      const regex = /^data:(image\/[a-zA-Z0-9-+]+);base64,(.+)$/;
      const match = base64.match(regex);
      console.warn(match);
      if (match) {
        const mime = match[1];
        const base64Data = match[2];
        return base64Data;
        const binary = atob(base64Data);
        const length = binary.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
          bytes[i] = binary.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: mime });
        console.warn('blog::',blob);
        return URL.createObjectURL(blob);
      } else {
        // console.error('Invalid base64 string:', base64);
        return ''; 
      }
    };
    

    return(
        <>
        <BannerList data={data} columns={columns} handleShow={handleShow}/>
        <BannerFormDialogue show={show} handleClose={handleClose} handleImageChange={handleImageChange} imagePreview={imagePreview} />
        </>
    )
    
}