export const GET_ALL = "GET_ALL";
export const GET_ALL_SUCCESS = "GET_ALL_SUCCESS";
export const GET_ALL_FAILURE = "GET_ALL_FAILURE";

export const CREATE_LOCATION = "CREATE_LOCATION";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const CREATE_LOCATION_FAILURE = "CREATE_LOCATION_FAILURE";

 


 