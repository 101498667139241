export const GET_ALL_APP_INFO_INIT = "GET_ALL_APP_INFO_INIT";
export const GET_ALL_APP_INFO_FAILURE = "GET_ALL_APP_INFO_FAILURE";
export const GET_ALL_APP_INFO_SUCCESS = "GET_ALL_APP_INFO_SUCCESS";

export const CREATE_APP_INFO_INIT = "CREATE_APP_INFO_INIT";
export const CREATE_APP_INFO_FAILURE = "CREATE_APP_INFO_FAILURE";
export const CREATE_APP_INFO_SUCCESS = "CREATE_APP_INFO_SUCCESS";

 

 