import React from 'react';
import { Navigate } from 'react-router-dom';
import HeaderPage from '../partials/Header';
const ProtectedRoute = ({ element: Element, isAuthenticated, ...rest }) => {
  
  if (isAuthenticated) {
    return (
      <>
        <HeaderPage />  
        <Element {...rest} />  
      </>
    );
  }
  return <Navigate to="/" />;
};

export default ProtectedRoute;
