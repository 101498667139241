import  React, { useState } from "react";
import {  Button,Modal,Form, Container, Col, Row, Card, CardHeader, CardBody } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function ManageInvoiceAddorEditPage(props) {
    return (
      <>
      <Row className="d-flex justify-content-between breadcrumb_main my-2">
        <Col className="d-flex align-items-center" style={{ whiteSpace:"nowwrap"}}>
            <span className="text-xl" style={{ fontSize:"150%"}}>Add Invoice</span>
        </Col>
        <Col className="d-flex justify-content-end" style={{ whiteSpace:"nowwrap", position:"inherit"}}>
            <nav aria-label="breadcrumb" style={{ display: "ruby" }}>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a></li>
                <li className="breadcrumb-item">
                  <a href="/add-invoice">Add Invoice</a>
                </li>
              </ol>
            </nav>
        </Col>
      </Row>
      <Card style={{ borderTop: "2px solid rgb(71, 35, 217)"}}>
        <CardHeader className="d-flex justify-content-between border-bottom pb-1">
          <div>Add Invoice</div>
          <Link to="/admin/manage-invoice" className="btn btn-info btn-sm">Back</Link>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6} mt={3}>
              <Form.Group className="mb-3" controlId="formPaymentMethod">
                  <Form.Label>Select Brand</Form.Label>
                  <Form.Select aria-label="Default select example" name="brand" id="brand">
                      <option value="">Select Brand</option>
                      <option value="1">On Host</option>
                      <option value="2">On Host2</option>
                  </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} mt={3}>
              <Form.Group className="mb-3" controlId="formAmount">
                <Form.Label>Full Name</Form.Label>
                <Form.Control type="text" name="fullName" id="fullName" placeholder="Full Name" />
              </Form.Group>
            </Col>
            <Col md={6} mt={3}>
              <Form.Group className="mb-3" controlId="formAmount">
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" name="email" id="email" placeholder="Email" />
              </Form.Group>
            </Col>
            <Col md={6} mt={3}>
              <Form.Group className="mb-3" controlId="formAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="text" name="amount" id="amount" placeholder="Amount" />
              </Form.Group>
            </Col>
            <Col md={12} mt={3}>
              <Form.Group className="mb-3" controlId="formAmount">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" name="description" id="description" placeholder="Description" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} mt={3}>
              <Button className="btn-sm btn-warning">Add Invoice</Button>
            </Col>
          </Row>
        </CardBody>

      </Card>
      </>
    )
}