import  React, { useState } from "react";
import {  Button,Modal,Form, FormControl, Row } from "react-bootstrap";
export default function AppInfoFormDialogue(props) {
    const { row,show, handleClose, validate,setRow,handleRadioChange, data } = props; 
    return (
        <>
        <Modal show={show} onHide={handleClose}>
        <Form onSubmit={(e) => {
          e.preventDefault();
            validate(row);
          }}>
        <Modal.Header closeButton>
          <Modal.Title>App Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Version IOS</Form.Label>
            <Form.Control
                type="text"
                value={row.versionIOS ?? null}
                onChange={(e) => setRow({
                  ...row,
                  versionIOS : e.target.value
                })}/>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Version Android</Form.Label>
            <Form.Control
              type="text"
              value={row.versionAndriod}
              onChange={(e) =>
                setRow({
                  ...row,
                  versionAndriod: e.target.value
                })
              }
            />
            

          </Form.Group>
          
          
          <Form.Group className="mb-3">
                <Form.Label>Build</Form.Label>
                <Form.Control
                type="text"
                value={row.build ?? null}
                onChange={(e) => setRow({
                  ...row,
                  build : e.target.value
                })}
              />
          </Form.Group>
          <Form.Group className="mb-3">
                <Form.Label>Build Date</Form.Label>
                <Form.Control
                type="date"
                value={row.buildDate}
                onChange={(e) => setRow({
                  ...row,
                  buildDate : e.target.value
                })}
              />
          </Form.Group>
        

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
        </>
    )
}