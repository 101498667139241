import React, { useState } from "react";
import { Card, CardBody, Col, Nav, CardHeader, Button } from "react-bootstrap";
import DataTableComponent from "../../../../partials/DataTableComponent";
import PaymentForm from "./PaymentForm";

export default function InternationalList(props) {
    const { data, columns, handleShow } = props;

    // Set default activeTab to 'paypal' or any other tab you want as default
    const [activeTab, setActiveTab] = useState('paypal');

    return (
        <>
            <Nav
                variant="pills"
                activeKey={activeTab}
                onSelect={(selectedKey) => setActiveTab(selectedKey)}
                style={{ display: "flex" }}
            >
                <Nav.Item>
                    <Nav.Link
                        eventKey="paypal"
                        className={`btn navLink ${activeTab === 'paypal' ? 'btn-primary' : 'btn-outline-primary'}`}
                    >
                        Paypal
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        eventKey="paypal-personal"
                        className={`btn navLink ${activeTab === 'paypal-personal' ? 'btn-primary' : 'btn-outline-primary'}`}
                    >
                        Paypal Personal
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        eventKey="stripe"
                        className={`btn navLink ${activeTab === 'stripe' ? 'btn-primary' : 'btn-outline-primary'}`}
                    >
                        Stripe
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        eventKey="paddle"
                        className={`btn navLink ${activeTab === 'paddle' ? 'btn-primary' : 'btn-outline-primary'}`}
                    >
                        Paddle
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        eventKey="perfect-money"
                        className={`btn navLink ${activeTab === 'perfect-money' ? 'btn-primary' : 'btn-outline-primary'}`}
                    >
                        Perfect Money
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        eventKey="api-method"
                        className={`btn navLink ${activeTab === 'api-method' ? 'btn-primary' : 'btn-outline-primary'}`}
                    >
                        API Method
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="tab-content mt-2">
                {activeTab === 'paypal' && <PaymentForm />}
                {activeTab === 'paypal-personal' && <PaymentForm />}
                {activeTab === 'stripe' && <PaymentForm />}
                {activeTab === 'paddle' && <PaymentForm />}
                {activeTab === 'perfect-money' && <PaymentForm />}
                {activeTab === 'api-method' && (
                    <Col xs={12}>
                        <Card>
                            <CardHeader className="d-flex">
                                <h5>Manage International Bank Api</h5>
                                <div className="ms-auto">
                                    <Button type="button" onClick={handleShow} size="sm">
                                        Add Mobile Bank (Api)
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </div>
        </>
    );
}
