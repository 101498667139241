import  React, { useState } from "react";
import { Tabs, Tab } from 'react-bootstrap';
import DataTableComponent from "../../partials/DataTableComponent";
import RewardList from "./components/List.component";
import { useNavigate } from "react-router-dom";
export default function RewardPage() {
    const [activeTab, setActiveTab] = useState('all');
    const navigate = useNavigate();
    const columns = [
        {
          name: 'ID',
          selector: row => row.id,
          sortable: true,
        },
        {
          name: 'Name',
          selector: row => row.name,
          sortable: true,
        },
        {
            name: 'Age',
            selector: row => row.name,
            sortable: true,
        },
        {
          name: 'Email',
          selector: row => row.email ,
          sortable: true,
        },
      ];
    const data = [
        { id: 1, name: 'John Doe', age: 28, email: 'john@example.com' },
     
      ];
    const handleShow = () => {
      navigate('/admin/create-rewards');
    }
    return(
        <RewardList data={data} columns={columns} handleShow={handleShow}  />
    )
    
}