import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash, faTrophy  } from "@fortawesome/free-solid-svg-icons";
import ResultsList from "./components/List";
import { useNavigate } from "react-router-dom";
import ResultFormDialogue from "./components/Form";

export default function ResultsPage() {
  const [show, setShow] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate()
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setImagePreview(null);
    setShow(true);
  };
  const handleEdit = (row) => {
    console.log(`Edit clicked for ID: ${row.id}`);
    setShow(true);
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ID: ${id}?`
    );
    if (confirmDelete) {
      // Proceed with the delete action
      console.log(`Deleted ID: ${id}`);
    }
  };

  const handleRedirect = () => {
    navigate('/admin/who-will-win');
  }
  
  const data = [
    {
      id: 1,
      question: "Who will win?",
      name: "",
      team1: "Edmonton Elks",
      team2: "Winnipeg Blue Bomber",
      endtime: "22 Sep 2024 02:00 PM",
      predictors: '1',
      status: "lost",
    },

  ];

  const columns = [
    {
      name: "SN",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "QUESTION",
      selector: (row) => row.question,
    },
    {
      name: "EVENT",
      selector: (row) => (
        <div>
          <div className="my-1">{row?.team1 || "Unknown Team 1"}</div>
          <div className="badge text-bg-info rounded-pill text-white">vs</div>
          <div className="my-1">{row?.team2 || "Unknown Team 2"}</div>
        </div>
      ),
    },
    {
      name: "END TIME",
      selector: (row) => row.endtime,
    },
    {
      name: "PREDICTORS",
      selector: (row) => (
        <div>
          <div className="badge text-bg-info rounded-pill text-white">{row.predictors}</div>
        </div>
      ),
    },
    {
      name: 'Action',
      cell: row => (
        <div>
          <button 
            onClick={() => handleRedirect()} 
            className="btn btn-link"
            title="Edit"
          >
            <FontAwesomeIcon icon={faTrophy} />
          </button>
          <button 
            onClick={() => handleEdit(row)} 
            className="btn btn-link"
            title="Edit"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
          <button 
            onClick={() => handleDelete(row.id)} 
            className="btn btn-link text-danger"
            title="Delete"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <ResultsList
        data={data}
        columns={columns}
        handleShow={handleShow}
      />
      <ResultFormDialogue
        show={show}
        handleClose={handleClose}
        handleImageChange={handleImageChange}
        imagePreview={imagePreview}
      />
    </>
  );
}
