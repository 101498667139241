import  React, { useState } from "react";
import {  Button,Modal,Form, Container, Col, Row, Card, CardHeader, CardBody } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTableComponent from "../../../partials/DataTableComponent";
export default function SystemTemplateFormPage() {
    
  const data = [
    { id: 1, name: 'John Doe', age: 28, email: 'john@example.com' },
    { id: 2, name: 'Jane Smith', age: 34, email: 'jane@example.com' },
    { id: 3, name: 'Alice Johnson', age: 25, email: 'alice@example.com' },
    { id: 4, name: 'Bob Brown', age: 42, email: 'bob@example.com' },
  ];

  const columns = [
  {
      name: 'Code',
      selector: row => row.id,
      sortable: true,
  },
  {
      name: 'Information',
      selector: row => row.name,
      sortable: true,
  },
  ];
    
         
    return(
        <>
        <Row className="d-flex justify-content-between breadcrumb_main my-2">
        <Col className="d-flex align-items-center" style={{ whiteSpace:"nowwrap"}}>
            <span className="text-xl" style={{ fontSize:"150%"}}>Template Setting</span>
        </Col>
        <Col className="d-flex justify-content-end" style={{ whiteSpace:"nowwrap", position:"inherit"}}>
            <nav aria-label="breadcrumb" style={{ display: "ruby" }}>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a></li>
                <li className="breadcrumb-item">
                  <a href="/add-invoice">Template Setting</a>
                </li>
              </ol>
            </nav>
        </Col>
      </Row>
      <Card style={{ borderTop: "2px solid rgb(71, 35, 217)"}}>
        <CardHeader className="d-flex justify-content-between border-bottom pb-1">
          <div>Template Setting</div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={12} mt={3}>
              <Form.Group className="mb-3" controlId="formAmount">
                <Form.Label>Bangla Template</Form.Label>
                <Form.Control as="textarea" rows={5} name="description" id="description" placeholder="" />
              </Form.Group>
            </Col>
            <Col md={12} mt={3}>
              <Form.Group className="mb-3" controlId="formAmount">
                <Form.Label>English Template</Form.Label>
                <Form.Control rows={5} as="textarea" name="description" id="description" placeholder="" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} mt={3}>
              <Button className="btn-sm btn-warning">Update</Button>
            </Col>
          </Row>
        </CardBody>

      </Card>
      
        {/* table  */}
        <div className="border mt-5 p-3 rounded">
          <span className="text-xl" style={{ fontSize:"100%"}}>Templete Short Codes</span>
          <table class="table table-striped border mt-5">
            <thead>
              <tr>
                <th scope="col">Code</th>
                <th scope="col">Information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">d23</th>
                <td>dd3d</td>
              </tr>
              <tr>
                <th scope="row">d23</th>
                <td>dd3d</td>
              </tr>
              <tr>
                <th scope="row">d23</th>
                <td>dd3d</td>
              </tr>
              <tr>
                <th scope="row">d23</th>
                <td>dd3d</td>
              </tr>
            </tbody>
          </table>
        </div>

        
        </>
    )
    
}