import { combineReducers } from 'redux';
import { tournamentReducer as tournamentPage } from '../api/tournament/tournamentReducer';
import { matchReducer as matchPage } from '../api/match/matchReducer';
import { leagueTemplateReducer as leagueTemplatePage} from '../api/leagueTemplate/leagueTemplateReducer';
import { userReducer as userPage } from '../api/user/userReducer';
import { bannerReducer as bannerPage } from '../api/banner/bannerReducer';
import { appInfoReducer as appInfo } from '../api/appInfo/appInfoReducer';
import { locationReducer as locationPage } from '../api/location/locationReducer';
const appReducer = combineReducers({
    tournamentPage,
    matchPage,
    leagueTemplatePage,
    userPage,
    bannerPage,
    appInfo,
    locationPage
});
export default appReducer;