export const GET_ALL = "GET_ALL";
export const GET_ALL_SUCCESS = "GET_ALL_SUCCESS";
export const GET_ALL_FAILURE = "GET_ALL_FAILURE";

export const CREATE_TOURNAMENT = "CREATE_TOURNAMENT";
export const CREATE_TOURNAMENT_SUCCESS = "CREATE_TOURNAMENT_SUCCESS";
export const CREATE_TOURNAMENT_FAILURE = "CREATE_TOURNAMENT_FAILURE";

export const UPDATE_TOURNAMENT = "UPDATE_TOURNAMENT";
export const UPDATE_TOURNAMENT_SUCCESS = "UPDATE_TOURNAMENT_SUCCESS";
export const UPDATE_TOURNAMENT_FAILURE = "UPDATE_TOURNAMENT_FAILURE";


 