import React, { useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
export default function TicketFormDialogue(props) {
  const { show, handleClose } = props;
  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="d-flex">
              <Col xs={12} sm={12} lg={12}>
                <div className="d-flex justify-content-between align-items-center mb-3 pb-3">
                  <h6>#C93CCA65 - I want to test it.</h6>
                  <button className="btn btn-sm btn-warning">Opened</button>
                </div>
              </Col>
              <Col xs={12} sm={12} lg={12}>
                <div className="border rounded p-2 mb-3" style={{minHeight:'8rem',height:'auto'}}>
                asdad
                </div>
              </Col>
              <Col xs={12} sm={12} lg={12}>
                <Form.Group className="mb-3" controlId="formPaymentDescription">
                  <Form.Label>
                    <b>Reply</b>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    placeholder=""
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary me-auto" onClick={handleClose}>
            Post
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
