import  React, { useState } from "react";
import { Card, CardBody, Col, Nav, CardHeader, Button  } from "react-bootstrap";
import DataTableComponent from "../../../partials/DataTableComponent";
export default function ManageDeviceList(props) {
    const { data,columns, activeTab,setActiveTab, handleShow } = props;
    return (
        <>
        <Nav variant="tabs" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)} style={{ display:"-webkit-inline-box" }}>
        <Nav.Item>
            <Nav.Link eventKey="all">All</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link eventKey="trash">Trash</Nav.Link>
        </Nav.Item>
    </Nav>
    <div className="tab-content mt-2">
    {activeTab === 'all' && (
        <Col xs={12}>
            <Card>
                <CardHeader className="d-flex">
                    <h5>Store data</h5>
                    <div className="ms-auto">
                       <Button type="button"  onClick={handleShow} size="sm">Add Store</Button>
                    </div>
                </CardHeader>
                <CardBody>
                <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                </CardBody>
            </Card>
        </Col>
      
    )}
    {activeTab === 'trash' && (
      <Col xs={12}>
            <Card>
                <CardHeader>
                    <h5>Trash Store Data</h5>
                </CardHeader>
                <CardBody>
                <DataTableComponent data={data} perPage={3} pageOption={[2, 4, 6]} />
                </CardBody>
            </Card>
        </Col>
    )}
    </div>
        </>
    )
}