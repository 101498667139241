import  React, { useState } from "react";
import BankTransferListPage from './components/ListPage';
import ManageInvoiceAddOrEdit from "./components/ListPage";
import { useNavigate } from "react-router-dom";
export default function BankTransferPage() {
    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const columns = [
        {
          name: 'ID',
          selector: row => row.id,
          sortable: true,
        },
        {
          name: 'Name',
          selector: row => row.name,
          sortable: true,
        },
        {
            name: 'Age',
            selector: row => row.name,
            sortable: true,
        },
        {
          name: 'Email',
          selector: row => row.email ,
          sortable: true,
        },
      ];

    const data = [
        { id: 1, name: 'John Doe', age: 28, email: 'john@example.com' },
        { id: 2, name: 'Jane Smith', age: 34, email: 'jane@example.com' },
        { id: 3, name: 'Alice Johnson', age: 25, email: 'alice@example.com' },
        { id: 4, name: 'Bob Brown', age: 42, email: 'bob@example.com' },
      ];
    const handleShowAddOrEditPage = () => {
        navigate('/admin/add-bank-transfer'); // Navigate to the add/edit page
    };
    return(
        <>
        <BankTransferListPage data={data} columns={columns} activeTab={activeTab} setActiveTab={setActiveTab} handleShow={handleShowAddOrEditPage} />
        
        </>
    )
    
}