import * as actionTypes from "./bannerActionType";
import axios from "axios";
const { REACT_APP_CRICKET_URL } = process.env;
 
export const getAllBanner = () => {
  const headers = {};
    return (dispatch) => {
        dispatch(getAllBannerInit());
        axios.get(`${REACT_APP_CRICKET_URL}/v5/bannerget`, {})
          .then((response) => {
            dispatch(getAllBannerSuccess(response.data));
          })
          .catch(function (error) {
            dispatch(getAllBannerFailure());
          });
      };
}
export const getAllBannerInit = () => ({
    type: actionTypes.GET_ALL_BANNER_INIT,
});
export const getAllBannerFailure = () => ({
    type: actionTypes.GET_ALL_BANNER_FAILURE,
});
export const getAllBannerSuccess = (data) => ({
    type: actionTypes.GET_ALL_BANNER_SUCCESS,
    payload: { data },
});



