import DashboardPage from './Dashboard.component';
import { connect } from 'react-redux';
import { getAllUser } from '../../api/user/userAction';
import { getAllMatch } from '../../api/match/matchAction';
const mapDispatchToProps = {
    getAllUser, getAllMatch
}
const mapStateToProps = state => ({
    result: state.userPage.result,
    totaleventresult: state.matchPage.result,
   
});
export const DashboardPageContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardPage); 