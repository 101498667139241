import React, { useState } from "react";
import { Button, Modal, Form, FormControl, Row, Col } from "react-bootstrap";

export default function PermissionFormDialogue(props) {
  const { show, handleClose, handleImageChange, imagePreview } = props;
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Permission Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col xs={12} sm={12} lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Name</b>
                  </Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
