import  React from "react";
import { Card, CardBody, Col, Nav, CardHeader, Button,Row,Form  } from "react-bootstrap";
import { Link } from "react-router-dom";
 
export default function LeagueFormPage(props) {
    const { data, columns,  handleShow } = props;
    return (
        <>
        <Row className="d-flex justify-content-between breadcrumb_main my-2">
        <Col className="d-flex align-items-center" style={{ whiteSpace:"nowwrap"}}>
            <span className="text-xl" style={{ fontSize:"150%"}}>Add League</span>
        </Col>
        <Col className="d-flex justify-content-end" style={{ whiteSpace:"nowwrap", position:"inherit"}}>
            <nav aria-label="breadcrumb" style={{ display: "ruby" }}>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a></li>
                <li className="breadcrumb-item">
                  <a href="/add-invoice">Add League</a>
                </li>
              </ol>
            </nav>
        </Col>
      </Row>
      <Card style={{ borderTop: "2px solid rgb(71, 35, 217)"}}>
        <CardHeader className="d-flex justify-content-between border-bottom pb-1">
          <div>Add League</div>
          <Link to="/admin/leagues" className="btn btn-info btn-sm">Back</Link>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6} mt={3}>
              <Form.Group className="mb-3">
                <Form.Label>League</Form.Label>
                <Form.Control type="text" name="league" id="league" placeholder="Enter League Name" />
              </Form.Group>
            </Col>
            
          </Row>
          <Row>
            <Col md={6} mt={3}>
              <Button className="btn-sm btn-success">Submit</Button>
            </Col>
          </Row>
        </CardBody>

      </Card>
        </>
    )
}