import * as actionType from "./leagueTemplateActionType";

const initialState = {
    isLoading: false,
    isSaved: false,
    result: [],
    metadata : [],
    count:0
};
export const leagueTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_LEAGUE_TEMPLATE_ALL:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.GET_ALL_LEAGUE_TEMPLATE_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.GET_ALL_LEAGUE_TEMPLATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                result: action?.payload?.data || [],
                metadata: action?.payload?.metadata || [],
            };

        case actionType.CREATE_LEAGUE_TEMPLATE_ALL:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.CREATE_LEAGUE_TEMPLATE_FAILURE:
            return {
                ...state,
                isLoading: false,
                isSaved: false,
            };
        case actionType.CREATE_LEAGUE_TEMPLATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved : true,
                result: action?.payload?.data || [],
                metadata: action?.payload?.metadata || [],
            };
        default: 
            return state;
    }
}