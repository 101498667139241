import React, { useState, useRef, useMemo } from "react";
import { Card, CardBody, Col, Nav, CardHeader } from "react-bootstrap";
import DataTableComponent from "../../../../partials/DataTableComponent";
import { Button, Modal, Form, FormControl, Row } from "react-bootstrap";
import Select from "react-dropdown-select";
import JoditEditor from "jodit-react";

export default function NotificationList(props) {
  const { data, columns, activeTab, setActiveTab } = props;

  //   react selecbox
  const [user, setUser] = useState("all user");
  const options = [
    {
      value: 1,
      label: "User1",
    },
    {
      value: 2,
      label: "User2",
    },
  ];

  //   jodit text editor
  const editor = useRef(null);
  const [content, setContent] = useState("");

  return (
    <>
      <Nav
        variant="tabs"
        activeKey={activeTab}
        onSelect={(selectedKey) => setActiveTab(selectedKey)}
        style={{ display: "-webkit-inline-box" }}
      >
        <Nav.Item>
          <Nav.Link eventKey="all">Send Via Email</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="completed">Send Via SMS</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="pending">Send Via Firebase</Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="tab-content mt-2">
        {activeTab === "all" && (
          <Col xs={12}>
            <Card className="border-top-color">
              <CardHeader>
                <h5>Send Via Email</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col xs={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Being Sent To</b>
                        </Form.Label>
                        <Form.Select
                          value={user}
                          onChange={(e) => setUser(e.target.value)}
                        >
                          <option value="all user">All Users</option>
                          <option value="active user">Active Users</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {user === "active user" ? (
                      <Col xs={12} sm={12} lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Selected User</b>
                          </Form.Label>
                          <Select
                            name="select"
                            options={options}
                            multi
                            className="rounded"
                          ></Select>
                        </Form.Group>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col xs={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Subject </b>
                        </Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Message </b>
                        </Form.Label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={(newContent) => {}}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={4} sm={4} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Start Form </b>
                        </Form.Label>
                        <Form.Control type="date" placeholder="" />
                      </Form.Group>
                    </Col>
                    <Col xs={4} sm={4} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Per Batch </b>
                        </Form.Label>
                        <Form.Control type="date" placeholder="" />
                      </Form.Group>
                    </Col>
                    <Col xs={4} sm={4} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Cooling Period </b>
                        </Form.Label>
                        <Form.Control type="date" placeholder="" />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        )}
        {activeTab === "completed" && (
          <Col xs={12}>
            <Card className="border-top-color">
              <CardHeader>
                <h5>Send Via SMS</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col xs={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Being Sent To</b>
                        </Form.Label>
                        <Form.Select
                          value={user}
                          onChange={(e) => setUser(e.target.value)}
                        >
                          <option value="all user">All Users</option>
                          <option value="active user">Active Users</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {user === "active user" ? (
                      <Col xs={12} sm={12} lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Selected User</b>
                          </Form.Label>
                          <Select
                            name="select"
                            options={options}
                            multi
                            className="rounded"
                          ></Select>
                        </Form.Group>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col xs={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Subject </b>
                        </Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Message </b>
                        </Form.Label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={(newContent) => {}}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={4} sm={4} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Start Form </b>
                        </Form.Label>
                        <Form.Control type="date" placeholder="" />
                      </Form.Group>
                    </Col>
                    <Col xs={4} sm={4} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Per Batch </b>
                        </Form.Label>
                        <Form.Control type="date" placeholder="" />
                      </Form.Group>
                    </Col>
                    <Col xs={4} sm={4} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Cooling Period </b>
                        </Form.Label>
                        <Form.Control type="date" placeholder="" />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        )}
        {activeTab === "pending" && (
          <Col xs={12}>
            <Card className="border-top-color">
              <CardHeader>
                <h5>Send Via Firebase</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col xs={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Being Sent To</b>
                        </Form.Label>
                        <Form.Select
                          value={user}
                          onChange={(e) => setUser(e.target.value)}
                        >
                          <option value="all user">All Users</option>
                          <option value="active user">Active Users</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {user === "active user" ? (
                      <Col xs={12} sm={12} lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Selected User</b>
                          </Form.Label>
                          <Select
                            name="select"
                            options={options}
                            multi
                            className="rounded"
                          ></Select>
                        </Form.Group>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col xs={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Subject </b>
                        </Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Message </b>
                        </Form.Label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={(newContent) => {}}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={4} sm={4} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Start Form </b>
                        </Form.Label>
                        <Form.Control type="date" placeholder="" />
                      </Form.Group>
                    </Col>
                    <Col xs={4} sm={4} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Per Batch </b>
                        </Form.Label>
                        <Form.Control type="date" placeholder="" />
                      </Form.Group>
                    </Col>
                    <Col xs={4} sm={4} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b> Cooling Period </b>
                        </Form.Label>
                        <Form.Control type="date" placeholder="" />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        )}
      </div>
    </>
  );
}
