import  React,{useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck  , faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import PendingDepositLogListPage from "./components/List.component";
import PendingDepositLogFormDialogue from "./components/Form";

export default function PendingDepositLogComponentPage() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => {
      setShow(true)
    }
    const handleEdit = (row) => {
      setShow(true)
    }
    const handleDelete = (id) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
        if (confirmDelete) {
            console.log(`Deleted ID: ${id}`);
        }
    }
    const columns = [
      {
        name: 'Sl',
        selector: row => row.id,
        sortable: true,
      },
      {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
      },
      {
        name: 'Gateway Name',
        selector: row => row.gateway,
        sortable: true,
      },
      {
        name: 'Amount',
        selector: row => row.amount,
        sortable: true,
      },
      {
        name: 'Charge',
        selector: row => row.charge,
        sortable: true,
      },
      {
        name: 'TRX',
        selector: row => row.trx,
        sortable: true,
      },
      {
        name: "Receipt",
        sortable: true,
        cell: (row) => (
          <button className="btn btn-sm btn-primary" onClick={()=>handleShow()}>Show</button>
        ),
      },
      {
        name: 'Action',
        cell: row => (
          <div>
            <button 
              className="btn btn-secondary me-2"
              title="Edit"
            >
              <FontAwesomeIcon icon={faCheck  } />
            </button>
            <button 
              onClick={() => handleDelete(row.id)} 
              className="btn btn-danger"
              title="Delete"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        ),
      },
    ];
    const data = [
      { id: 1, name: 'Allen Mercy', gateway: 'Mobllie', amount: '1000 USD', charge: '0 USD', usd:'11.1 USD', trx:'S6NEP2G95VAW'},
      { id: 2, name: 'Allen Mercy', gateway: 'Mobllie', amount: '1000 USD', charge: '0 USD', usd:'11.1 USD', trx:'S6NEP2G95VAW'},
      { id: 3, name: 'Allen Mercy', gateway: 'Mobllie', amount: '1000 USD', charge: '0 USD', usd:'11.1 USD', trx:'S6NEP2G95VAW'},
  ];
  
    return(
        <>
        <PendingDepositLogListPage data={data} columns={columns}  handleShow={handleShow}/>
        <PendingDepositLogFormDialogue
            show={show}
            handleClose={handleClose}
          />
        </>
    )
}