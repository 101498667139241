import  React, { useState } from "react";
import { Card, CardBody, Col, Nav, CardHeader, Button  } from "react-bootstrap";
import DataTableComponent from "../../../partials/DataTableComponent";
export default function RoleAccessList(props) {
    const { data,columns } = props;
    return (
        <>
       <Col xs={12}>
            <Card>
                <CardHeader className="d-flex">
                    <h5>Role Access</h5>
                </CardHeader>
                <CardBody>
                    {
                        data && data.map((val) => {
                            return (<>
                                <hr className="top-border-dotted"/>
                                <h5><strong>{val.name}</strong></h5>
                                <div className="d-flex">
                                    <div className="col-3">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                            <label class="form-check-label" for="flexSwitchCheckDefault">Create</label>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                            <label class="form-check-label" for="flexSwitchCheckDefault">Edit</label>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                            <label class="form-check-label" for="flexSwitchCheckDefault">Update</label>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                            <label class="form-check-label" for="flexSwitchCheckDefault">Delete</label>
                                        </div>
                                    </div>
                                </div>
                             </>)
                        })
                    }
                            
 

                        

                        
                    
                </CardBody>
            </Card>
        </Col>
        </>
    )
}