import  React, { useState } from "react";
import StoreDataList from './components/List';
import StoreDataFormDialogue from "./components/Form";
import InternationalList from "./components/List";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faTrash } from '@fortawesome/free-solid-svg-icons';

export default function InternationalPage() {
    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const  handleEdit = (row) => {
      console.log(`Edit clicked for ID: ${row.id}`);
      setShow(true)
    }

    const handleDelete = (id) => {
      const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
      if (confirmDelete) {
        // Proceed with the delete action
        console.log(`Deleted ID: ${id}`);
      }
    }

    const columns = [
        {
          name: 'Brand',
          selector: row => row.brand,
          sortable: true,
        },
        {
          name: 'Name',
          selector: row => row.name,
          sortable: true,
        },
        {
            name: 'Min Amount',
            selector: row => row.min,
            sortable: true,
        },
        {
          name: 'Max Amount',
          selector: row => row.max ,
          sortable: true,
        },
        {
          name: 'Status',
          sortable: true,
          cell: row => (
            <div>
              <span style={{fontSize:'14px'}} className={`badge ${row.status == 'active' ? 'text-bg-success' : 'text-bg-danger' }`}>{row.status}</span>
            </div>
          )
        },
        {
          name: 'Action',
          cell: row => (
            <div>
              <button 
                onClick={() => handleEdit(row)} 
                className="btn btn-link"
                title="Edit"
              >
                <FontAwesomeIcon icon={faPencilAlt } />
              </button>
              <button 
                onClick={() => handleDelete(row.id)} 
                className="btn btn-link text-danger"
                title="Delete"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          ),
        }
      ];
    const data = [
        { brand: 'onhost', name: 'John Doe', min: 28, max: '46', status: 'active' },
        { brand: 'onhost', name: 'John Doe', min: 28, max: '46', status: 'inactive' },
        { brand: 'onhost', name: 'John Doe', min: 28, max: '46', status: 'inactive' },
        { brand: 'onhost', name: 'John Doe', min: 28, max: '46', status: 'active' },
      ];
    return(
        <>
        <InternationalList data={data} columns={columns} activeTab={activeTab} setActiveTab={setActiveTab} handleShow={handleShow}/>
        <StoreDataFormDialogue show={show} handleClose={handleClose} />
        </>
    )
    
}