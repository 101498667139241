import  React, { useState } from "react";
import { Card, CardBody, Col, Nav, CardHeader, Button  } from "react-bootstrap";
import DataTableComponent from "../../../partials/DataTableComponent";
export default function UserList(props) {
    const { data, columns } = props;
    return (
        <Col xs={12}>
        <Card>
            <CardHeader className="d-flex">
                <h5>Users</h5>
            </CardHeader>
            <CardBody>
            <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
            </CardBody>
        </Card>
    </Col>
    )
}