import React from "react";
import "./DetailPage.css";

const DetailPage = () => {
  return (
    <div className="container-xl px-0 px-sm-4 my-4">
      <div className="row">
        <div className="col-12 mx-auto">
          {/* Account details card*/}
          <div className="card mb-4 mt-4 mt-sm-0">
            <div className="card-header">General Setting</div>
            <div className="card-body">
              <form>
                {/* Form Row*/}
                <div className="row gx-3 mb-3">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputFirstName">
                      Site Title
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                  
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputLastName">
                      Base Currency
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputSelectMenu">
                      Timezone
                    </label>
                    <select
                      className="form-control py-3"
                      aria-label="Default select example"
                    >
                      <option value="1">Asia/Dhaka</option>
                      <option value="2">Asuia/Delhi</option>
                    </select>
                  </div>
                </div>

                {/* Save changes button*/}
                <button className="btn btn-primary" type="button">
                  Save changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
