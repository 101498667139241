import React, { useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
export default function StoreDataFormDialogue(props) {
  const { show, handleClose } = props;
  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Mobail Bank (Api)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="d-flex">
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3" controlId="formPaymentMethod">
                  <Form.Label><b>Select Brand</b></Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option value="1">onhost</option>
                    <option value="2">onhost2</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3" controlId="formPaymentMethod">
                  <Form.Label><b>Payment Method Type</b></Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option value="1">onhost</option>
                    <option value="2">onhost2</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3" controlId="formAmount">
                  <Form.Label><b>Full Name</b></Form.Label>
                  <Form.Control type="text" placeholder="Full Name" />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3" controlId="formAmount">
                  <Form.Label><b>Transaction Fee</b></Form.Label>
                  <Form.Control type="number" placeholder="" />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3" controlId="formAmount">
                  <Form.Label><b>Min Amount</b></Form.Label>
                  <Form.Control type="number" placeholder="Min Amount" />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3" controlId="formAmount">
                  <Form.Label><b>Max Amount</b></Form.Label>
                  <Form.Control type="number" placeholder="Max Amount" />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary me-auto" onClick={handleClose}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
