import  React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Form, Row, Button } from "react-bootstrap";
import { Link,useNavigate } from "react-router-dom";
import { isEqual } from 'lodash';
export default function LoginPage() {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [row, setRow] = useState({});
    const [authenticateUser,setAuthenticateUser] = useState({
        id : "1",
        email : "admin@skipper.com",
        username:"skipper",
        password : "12345678"
    });
    
    const login = (userData) => {
        userData['id']= '1';
        userData['username']= 'skipper';
        if(isEmpty(userData)) {
            localStorage.setItem('userDetails', {}); 
        }
        setAuthenticateUser(authenticateUser);
        console.warn(isEqual(authenticateUser, userData));
        if (isEqual(authenticateUser, userData)) {
            localStorage.setItem('isAuthenticated', 'true');  
            localStorage.setItem('userDetails', JSON.stringify(authenticateUser));  
            console.warn('Objects are the same');
            setIsAuthenticated(true);
          
        } else {
            console.warn('Objects are different');
            localStorage.setItem('isAuthenticated', 'false'); 
        }
    }

     

    const isEmpty = (obj) => {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            return false;
          }
        }
        return true;
      };
    

    useEffect(() => {
        if(isAuthenticated) {
            console.warn('User is authenticated:', authenticateUser);
            localStorage.setItem('userDetails', JSON.stringify(authenticateUser));  
            navigate('/admin/dashboard');
        } 
    },[isAuthenticated])

  return (
    <>
        <div className="justify-content-center align-items-center min-vh-100 bg-light">
            <Container className="p-4" style={{ maxWidth: '800px' }}>
                <Card>
                    <Row>
                        <Col md={6}>
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp" alt="login form" className='rounded-start w-100'></img>
                        </Col>
                        <Col md={6}>
                            <CardBody className='flex-column'>
                                <div className='d-flex flex-row mt-2'>
                                    {/* icon */}
                                    <span className="h1 fw-bold mb-0">Logo</span>
                                </div>
                                <h5 className="fw-normal my-4 pb-3" style={{letterSpacing: '1px'}}>Sign into your account</h5>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                        login(row);
                                    }}>
                                <Form.Group className="pb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control 
                                    type="email" 
                                    name="email"
                                    placeholder="Enter email"
                                    onChange={(e) => setRow({
                                        ...row,
                                        email : e.target.value
                                    })}
                                    />
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control 
                                        type="password"
                                        name="password" 
                                        placeholder="Password"
                                        onChange={(e) => setRow({
                                            ...row,
                                            password : e.target.value
                                        })}
                                    />
                                </Form.Group>
                                
                                <Button className="mb-4 px-5"  variant="success" type="submit">
                                    Submit
                                </Button>

                                </Form>
                                {/* <Button onClick={() => navigate("/admin/dashboard")} className="mb-4 px-5"  variant="success" type="submit">
                                    Submit
                                </Button> */}
                                <br/>
                                <a className="small text-muted" href="#!">Forgot password?</a>
                                <p className="mb-5 pb-lg-2" style={{color: '#393f81'}}>Don't have an account? <a href="#!" style={{color: '#393f81'}}>Register here</a></p>
                                <div className='d-flex flex-row justify-content-start'>
                                    <a href="#!" className="small text-muted me-1">Terms of use.</a>
                                    <a href="#!" className="small text-muted">Privacy policy</a>
                                </div>

                            </CardBody>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </div>
    </>
     
  )
}